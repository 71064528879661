import axios from "axios";

export const getBalanceLineByIdRequest = ({ ref }) => axios.get(`/balanceline/${ref}`)

export const deleteBalanceLineRequest = ({ ref }) => axios.delete(`/balanceline/${ref}`, {})

export const updateBalanceLineRequest = ({ logDate, logTime, ref, title, total }) => axios.put(`/balanceline/${ref}`, { logDate, logTime, ref, title, total })

export const AllBalanceByElevatorRefRequest = ({ ref }) => axios.get(`/balanceline/${ref}/balance`)

export const chargeRequest = ({ ref, description, amount, timeString, dateString }) =>
    axios.post(`/elevator/${ref}/charge?description=${description}&amount=${amount}&timeString=${timeString}&dateString=${dateString}`)

export const payRequest = ({ ref, description, amount, timeString, dateString }) =>
    axios.post(`/elevator/${ref}/pay?description=${description}&amount=${amount}&timeString=${timeString}&dateString=${dateString}`)
