import { DatePicker, LocalizationProvider, TimePicker } from "@mui/lab"
import AdapterDateFns from "@mui/lab/AdapterDateFns"
import { Paper, Typography, useTheme, Box, Badge, Button, Autocomplete, TextField, MenuItem, Divider, Grid } from "@mui/material"
import { format, getHours, getTime } from "date-fns"
import { tr } from "date-fns/locale"
import { useSnackbar } from "notistack"
import React, { useEffect, useState } from 'react'
import { useTranslation } from "react-i18next"
import NumberFormat from "react-number-format"
import { useHistory } from "react-router-dom"
import { chargeRequest, payRequest } from "../api/controllers/accounting-controller"
import { getUnReadRequest } from "../api/controllers/event-history-controller"
import { getCurrentCompanyRequest, getCurrentUserRequest } from "../api/controllers/home-controller"
import CustomDataGrid, { TableMenu } from "../components/CustomDataGrid"
import CustomDialog from "../components/CustomDialog"
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import PhoneInTalkIcon from '@mui/icons-material/PhoneInTalk';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import { LoadingButton } from "@mui/lab"
import CountryPicker, { countries } from "../components/CountryPicker";


const Page = () => {
    const { t } = useTranslation("common");
    document.title = 'Aselmind | ' + t("destek")
    const theme = useTheme()
    const { enqueueSnackbar } = useSnackbar();
    const history = useHistory();

    const [userData, setUserData] = useState({})
    const [companyData, setCompanyData] = useState({})

    const [formData, setFormData] = useState({})
    const [selectedCountry, setSelectedCountry] = useState(null)

    const [isDisabled, setIsDisabled] = useState(false)

    // GET USER DATA
    const fetchUserData = async () => {
        try {
            let res = await getCurrentUserRequest()
            if (res) {
                setUserData(res?.data)
            }
        } catch (error) {
            console.log('error', error)
        }
    }

    // GET COMPANY LOGO
    const fetcCompanyLogo = async () => {
        try {
            let res = await getCurrentCompanyRequest()
            if (res) {
                setCompanyData(res?.data)
            }
        } catch (error) {

        }
    }

    //SEND CONTACT FORM
    const handleSave = () => {
        formData.telefonNo = selectedCountry?.phone + '~' + formData?.telefonNo
        setFormData({ ...formData })
        /* burayı isteğe ekle yukarıyı kapat {...formData, telefonNo: selectedCountry?.phone + '~' + formData?.telefonNo } */

        setIsDisabled(true)
        setTimeout(() => {
            setIsDisabled(false)
        }, 1500);
        enqueueSnackbar(t('çokyakında'), { variant: "info" });

    }



    useEffect(() => {
        fetchUserData()
        fetcCompanyLogo()

    }, [])


    return (
        <Box p={3}>

            <Paper
                elevation={0}
                sx={{
                    display: "flex",
                    padding: theme.spacing(2),
                    alignItems: "center",
                    justifyContent: "space-between",
                    flexDirection: { xs: 'column', sm: 'column', md: 'row', lg: "row" }
                }}
            >
                <Typography sx={{ fontSize: window.innerWidth < 675 ? "1.75rem" : "2.125rem" }} variant="h4">{t('destek')}</Typography>

            </Paper>
            <Paper
                elevation={0}
                sx={{
                    padding: theme.spacing(2),
                    margin: theme.spacing(2, 0),
                }}
            >

                <Box sx={{ display: "flex", padding: { xs: "12px 24px", sm: "12px 24px", md: "12px 24px", lg: "24px 48px" } }}>
                    <Grid container spacing={{ xs: 2, md: 5 }}>
                        <Grid item xs={12} md={6}>
                            <Grid container spacing={1}>
                                <Grid item xs={12}>
                                    <Box>
                                        {t("adınızsoyadınız")}
                                    </Box>
                                </Grid>
                                <Grid item xs={12} >
                                    <TextField
                                        variant="standard"
                                        /* label={t("depoadı")} */
                                        fullWidth
                                        value={formData?.adSoyad}
                                        onChange={(e) => {
                                            formData.adSoyad = e.target.value
                                            setFormData({ ...formData })
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <Box>
                                        {t("telefonnumaranız")}
                                    </Box>
                                </Grid>
                                <Grid item xs={12} >
                                    <Box style={{ display: "flex", flexDirection: "row", }}>
                                        <CountryPicker value={selectedCountry ? selectedCountry : countries[223]} onchange={(e, newValue) => {
                                            setSelectedCountry(newValue)
                                        }} />
                                        <NumberFormat
                                            style={{ marginTop: "16px" }}
                                            customInput={TextField}
                                            allowEmptyFormatting
                                            /*    format="+90 ### ### ## ##" */
                                            variant="standard"
                                            /*  label={t('yöneticitelefonnumarası')} */
                                            fullWidth
                                            value={formData?.telefonNo}
                                            onChange={(e) => {
                                                formData.telefonNo = e.target.value
                                                setFormData({ ...formData })
                                            }}
                                        />
                                    </Box>
                                </Grid>
                                <Grid item xs={12}>
                                    <Box>
                                        {t("mailadresiniz")}
                                    </Box>
                                </Grid>
                                <Grid item xs={12} >
                                    <TextField
                                        variant="standard"
                                        /* label={t("depoadı")} */
                                        fullWidth
                                        value={formData?.mail}
                                        onChange={(e) => {
                                            formData.mail = e.target.value
                                            setFormData({ ...formData })
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <Box>
                                        {t("mesajınız")}
                                    </Box>
                                </Grid>
                                <Grid item xs={12} >
                                    <TextField
                                        sx={{ margin: "20px 0 10px 0" }}
                                        /*placeholder={t('eğereklemekistediğiniznotlarvarsabualanaekleyebilirsiniz')} */
                                        variant='standard'
                                        multiline={true}
                                        /*rows={2} */
                                        fullWidth
                                        value={formData?.note}
                                        onChange={(e) => {
                                            formData.note = e.target.value
                                            setFormData({ ...formData })
                                        }}
                                    />

                                </Grid>
                                <Grid item xs={12}>
                                    <Box sx={{ display: "flex", justifyContent: "flex-end", }}>
                                        <LoadingButton
                                            loading={isDisabled}
                                            disableElevation
                                            sx={{
                                                margin: theme.spacing(1),
                                                width: { xs: "100%", sm: "100%", md: "auto", lg: "auto" }
                                            }}
                                            variant="contained"
                                            onClick={() => handleSave()}
                                            color="secondary">{t("gönder")}</LoadingButton>
                                    </Box>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <Box sx={{ display: "flex", width: "100%", backgroundColor: "rgba(77,77,77,1)", borderRadius: "10px", padding: { xs: "12px 24px", sm: "12px 24px", md: "12px 24px", lg: "24px 48px" } }}>
                                {/*  <Typography sx={{ color: "white" }}>
                                    asd
                                </Typography> */}
                                <Grid container spacing={2}>
                                    <Grid item xs={12}>
                                        <Typography sx={{ fontSize: "24px", color: "white" }}>
                                            {t("aselsisendüstriyelaş")}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                                            <MailOutlineIcon sx={{ color: "white" }} />
                                            <Typography sx={{ marginLeft: "10px", color: "white" }}>
                                                {t("aselsis@aselsis.com.tr")}
                                            </Typography>
                                        </div>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                                            <PhoneInTalkIcon sx={{ color: "white" }} />
                                            <Typography sx={{ marginLeft: "10px", color: "white" }}>
                                                {t("+90 850 840 10 56")}
                                            </Typography>
                                        </div>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                                            <LocationOnIcon sx={{ color: "white" }} />
                                            <Typography sx={{ marginLeft: "10px", color: "white" }}>
                                                {t("Kınıklı Mahallesi 6025 Sokak No:16 20160 Pamukkale / Denizli")}
                                            </Typography>
                                        </div>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Typography sx={{ color: "white" }}>
                                            {t("bizisosyalmedyadantakipedin")}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                                            <a href="https://www.facebook.com/aselsis" target="_blank">
                                                <img style={{ cursor: "pointer", marginRight: "10px" }} src='/assets/face.svg' />
                                            </a>
                                            <a href="https://www.instagram.com/aselsis" target="_blank">
                                                <img style={{ cursor: "pointer", marginRight: "10px" }} src='/assets/insta.svg' />
                                            </a>
                                            <a href="https://www.linkedin.com/company/aselsis" target="_blank">
                                                <img style={{ cursor: "pointer", marginRight: "10px", color: "white", backgroundColor: "rgba(77,77,77,1)" }} src='/assets/linkedin.svg' />
                                            </a>
                                        </Box>
                                    </Grid>
                                </Grid>
                            </Box>
                        </Grid>
                    </Grid>
                </Box>

            </Paper >

        </Box >
    )

}

export default Page