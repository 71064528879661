import CustomThemeProvider from "./CustomThemeProvider"
import Router from "./Router"
import React from 'react'
import ApiProvider from "../api/ApiProvider"
import { CssBaseline } from '@mui/material';
import { useTranslation } from "react-i18next";

const App = () => {
  const { t } = useTranslation("common");

  document.getElementsByTagName("META")[3].content =  t("description") 

  return (

    <CustomThemeProvider>
      <ApiProvider>
        <CssBaseline />
        <Router />
      </ApiProvider>
    </CustomThemeProvider>
  )
}

export default App;