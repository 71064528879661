import axios from "axios";

export const loginRequest = ({ companyCode, userTitle, userPassword }) => axios.post(`/login?companyCode=${companyCode}&userTitle=${userTitle}&userPassword=${userPassword}`, {})

export const indexRequest = () => axios.get(`/`)

export const getCurrentCompanyRequest = () => axios.get(`/currentCompany`)

export const getCurrentUserRequest = () => axios.get(`/currentUser`)

export const dateRequest = () => axios.get(`/datetime`)

export const initdbRequest = ({ companyCode }) => axios.get(`/initcompany?companyCode=${companyCode}`)

export const logoutRequest = () => axios.post(`/logout`)

export const sessionCheckRequest = () => axios.get(`/sessioncheck`)

export const sessionTimeOutRequest = () => axios.get(`/sessionTimeOut`)

export const timezoneRequest = () => axios.get(`/timezone`)

export const timezoneidRequest = () => axios.get(`/timezoneid`)

export const updateDeviceIdRequest = ({ token }) => axios.post(`/updateToken`,{token})

export const versionRequest = () => axios.get(`/version`)

export const getSearchCompanyRequest = ({code}) => axios.get(`/company/code/${code}`)
