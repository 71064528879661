import axios from "axios"
import { useMemo } from "react"

//export const BASE_URL = "https://aselmind.com/aselmind-api"
//export const BASE_URL = "http://localhost:3000"
// axios.defaults.baseURL = "https://aselmind.com/aselmind-api"
//export const BASE_URL = "http://localhost:8080";
export const BASE_URL = process.env.REACT_APP_API_URL;

//axios.defaults.baseURL = "https://aselmind.aselsis.com/api/"
axios.defaults.baseURL = BASE_URL
//axios.defaults.baseURL = "http://10.10.10.100:8080"
//axios.defaults.baseURL = "http://10.10.10.168:8080"


axios.defaults.withCredentials = true
axios.defaults.crossDomain = true

const ApiProvider = ({ children }) => {
    useMemo(() => {
        // axios.interceptors.request.use(async (config) => {
        //     // config.headers["cookie"] = document.cookie
        //     // config.withCredentials = true
        //     // config.headers["origin"] = "*"
        //     return config
        // }, async () => {
        // });
        axios.interceptors.response.use(async (response) => {


            // if(response?.headers["Set-Cookie"]) {
            //     document.cookie = response?.headers["Set-Cookie"]
            // }

            return response;
        }, async (error) => new Promise((resolve, reject) => {
            return reject(error)
        }));
    }, [])
    return children

}



export default ApiProvider