import { Autocomplete, Box, Button, Fade, Grid, IconButton, Menu, MenuItem, Paper, TextField, Typography, useTheme } from "@mui/material"
import { useSnackbar } from "notistack"
import React, { useEffect, useState } from 'react'
import CustomDataGrid, { TableMenu } from "../../components/CustomDataGrid"
import { CityGetAllOperationRequest, GetNeighborhoodsOfTownsWithIdRequest, GetTownsOfCityWithIdRequest } from "../../api/controllers/addresses-controller";
import CustomDialog from "../../components/CustomDialog"
import NumberFormat from "react-number-format"
import { getAllItemsRequest } from "../../api/controllers/item-controller"
import { deleteRequest, getAllRequest, insertRequest } from "../../api/controllers/user-item-line-controller"
import { useTranslation } from "react-i18next"
import jsPDF from "jspdf"
import "jspdf-autotable";
import { CSVLink } from "react-csv";
import DownloadIcon from '@mui/icons-material/Download';
import { ExpandMore } from "@mui/icons-material"
import DeleteIcon from '@mui/icons-material/Delete';
import { myFont } from "../../components/myFontForPdf";


const Page = () => {
    const { t } = useTranslation("common");
    document.title = 'Aselmind | ' + t("kişizimmetbilgisi")
    const theme = useTheme()
    const { enqueueSnackbar } = useSnackbar();
    var url = window.location;
    let ref = new URLSearchParams(url.search).get('ref');


    //GET DEBIT DATA
    const [data, setData] = useState([])
    const [selectedDebitData, setSelectedDebitData] = useState([])

    const fetcDebitData = async () => {
        try {
            let res = await getAllRequest(ref)
            if (res) {
                setData(res?.data)
            }
        } catch (error) {
            console.log(error)
            enqueueSnackbar(t('birşeylertersgitti'), { variant: "error" });
        }
    }


    //DELETE DEBIT
    const [deleteDebitDialog, setDeleteDebitDialog] = useState(false)

    const openDeleteDebitDialog = async (Debit) => {
        await setSelectedDebitData(Debit)
        setDeleteDebitDialog(true)
    }

    const closeDeleteDebitDialog = () => {
        setDeleteDebitDialog(false)
        setSelectedDebitData([])
    }

    const handleDeleteDebit = async () => {
        try {
            let res = await deleteRequest(selectedDebitData?.ref)
            if (res) {
                closeDeleteDebitDialog()
                fetcDebitData()
                enqueueSnackbar(t("depobaşarıylasilindi"), { variant: "success" });
            }
        } catch (error) {
            enqueueSnackbar(t('birşeylertersgitti'), { variant: "error" });
            console.log(error)
        }
    }


    const columns = [
        {
            field: 'user',
            headerName: t("kişi"),
            minWidth: 130,
            flex: 1,
            valueGetter: ({ value }) => value.userName ? value.userName + ' ' + value.userSurName : <Typography sx={{ opacity: .6, fontSize: '14px' }}>{t("Belirtilmemiş")}</Typography>,
        },
        {
            field: 'item',
            headerName: t("malzeme"),
            minWidth: 130,
            flex: 1,
            valueGetter: ({ value }) => value?.title ? value?.title : <Typography sx={{ opacity: .6, fontSize: '14px' }}>{t("Belirtilmemiş")}</Typography>,
        },
        {
            field: 'amount',
            headerName: t("adet"),
            minWidth: 130,
            flex: 1,
            renderCell: ({ value }) => value ? value : <Typography sx={{ opacity: .6, fontSize: '14px' }}>{t("Belirtilmemiş")}</Typography>,
        },
        {
            field: 'logDate',
            headerName: t("zimmettarihi"),
            minWidth: 130,
            flex: 1,
            renderCell: ({ value }) => value ? value : <Typography sx={{ opacity: .6, fontSize: '14px' }}>{t("Belirtilmemiş")}</Typography>,
        },
        {
            field: 'logTime',
            headerName: t("zimmetsaati"),
            minWidth: 130,
            flex: 1,
            renderCell: ({ value }) => value ? value : <Typography sx={{ opacity: .6, fontSize: '14px' }}>{t("Belirtilmemiş")}</Typography>,
        },
        {
            field: 'ref',
            headerName: <></>,
            minWidth: 50,
            type: 'actions',
            align: 'right',
            flex: 1,
            renderCell: (params) => <IconButton aria-label="delete" key={"delete"}
                sx={{
                    '&:hover': {
                        color: "#C0392B",
                    }
                }} onClick={() => openDeleteDebitDialog(params?.row)}>
                <DeleteIcon />
            </IconButton>
        }
    ]

    // DEBIT
    const [debitDialog, setDebitDialog] = useState(false)
    const [addDebitData, setAddDebitData] = useState({})

    //GET ALL ITEMS
    const [items, setItems] = useState([])

    // PDF EXPORTING
    const exportPdf = () => {
        const unit = "pt";
        const size = "A4"; // Use A1, A2, A3 or A4
        const orientation = "portrait"; // portrait or landscape

        const marginLeft = 40;
        const doc = new jsPDF(orientation, unit, size);

        doc.addFileToVFS("Amiri-Regular.ttf", myFont());
        doc.addFont("Amiri-Regular.ttf", "Amiri", "normal");
        doc.setFont("Amiri"); // set font
        doc.setFontSize(15);

        const title = t("kişizimmetbilgisi");
        const headers = [[t("kişi"), t("malzeme"), t("adet"), t("zimmettarihi"), t("zimmetsaati")]];
        const data2 = data.map(elt => [elt?.user?.userName + ' ' + elt?.user?.userSurName, elt?.item?.title, elt?.amount, elt?.logDate, elt?.logTime]);

        let content = {
            startY: 50,
            head: headers,
            body: data2,
            styles: {
                font: 'Amiri',
                fontStyle: 'normal',
            },
            columnStyles: {
                0: { cellWidth: 140 },
                1: { cellWidth: 100 },
                2: { cellWidth: 70 },
                3: { cellWidth: 105 },
                4: { cellWidth: 100 },
                // etc 515px / alan 
            }
        };

        doc.text(title, marginLeft, 40);
        doc.autoTable(content);
        doc.save("report.pdf")
    }

    // EXCEL EXPORTING
    const headers5 = [
        { label: t("kişi"), key: "kişi" },
        { label: t("malzeme"), key: "malzeme" },
        { label: t("adet"), key: "adet" },
        { label: t("zimmettarihi"), key: "zimmettarihi" },
        { label: t("zimmetsaati"), key: "zimmetsaati" }
    ];
    const data5 = data.map((elt) => (
        { kişi: elt?.user?.userName ? elt?.user?.userName + ' ' + elt?.user?.userSurName : '', malzeme: elt?.item?.title, adet: elt?.amount, zimmettarihi: elt?.logDate, zimmetsaati: elt?.logTime }
    ))

    const [menuOpen, setMenuOpen] = useState(false)
    const [anchorEl, setAnchorEl] = useState(null)

    useEffect(() => {
        fetcDebitData();

    }, [])


    return (
        <Box p={3}>

            <Paper
                elevation={0}
                sx={{
                    display: "flex",
                    padding: theme.spacing(2),
                    alignItems: "center",
                    justifyContent: "space-between",
                    flexDirection: { xs: 'column', sm: 'column', md: 'row', lg: "row" }
                }}
            >
                <Typography sx={{ fontSize: window.innerWidth < 675 ? "1.75rem" : "2.125rem" }} variant="h4">{t("kişizimmetbilgisi")}</Typography>


            </Paper>
            <Paper
                elevation={0}
                sx={{
                    padding: theme.spacing(2),
                    margin: theme.spacing(2, 0),
                }}
            >
                <div style={{ display: "flex", justifyContent: "flex-end", paddingBottom: "16px" }}>
                    <Button
                        onClick={(e) => {
                            setAnchorEl(e.currentTarget)
                            setMenuOpen(true);
                        }}
                        variant="outlined"
                        color="primary"
                        endIcon={<ExpandMore />}
                        sx={{
                            minHeight: 40,
                        }}
                    >


                        <div>{t("raporla")}</div>


                    </Button>

                    <Menu
                        anchorEl={anchorEl}
                        open={menuOpen}
                        onClose={() => {
                            setMenuOpen(false)
                            setAnchorEl(null)
                        }}
                        TransitionComponent={Fade}
                        PaperProps={{
                            elevation: 3,
                            sx: {
                                width: "auto",
                                minWidth: anchorEl?.offsetWidth || "auto",
                            }
                        }}
                    >
                        <MenuItem>
                            <Button startIcon={<DownloadIcon />} variant="outlined" onClick={() => exportPdf()} sx={{ color: "#c0392b", borderColor: "#c0392b", minWidth: "100px" }} >{t("pdf")}</Button>
                        </MenuItem>

                        <MenuItem>
                            <CSVLink data={data5} headers={headers5} separator={";"} filename={"kişi-zimmet-bilgisi.csv"}>
                                <Button startIcon={<DownloadIcon />} variant="outlined" sx={{ color: "#4CAF50", borderColor: "#4CAF50", minWidth: "100px" }} >{t("excel")}</Button>
                            </CSVLink>
                        </MenuItem>

                    </Menu>

                </div>
                <CustomDataGrid
                    columns={columns}
                    data={data}
                    rowId={params => params?.ref}
                />

            </Paper>


            {/* DELETE Debit DIALOG */}
            <CustomDialog
                maxWidth="xs"
                title={t("eminmisiniz")}
                open={deleteDebitDialog}
                close={closeDeleteDebitDialog}
                buttons={[
                    <Button key="cancel" onClick={() => closeDeleteDebitDialog()}>{t("iptalet")}</Button>,
                    <Button key="save" onClick={() => handleDeleteDebit()} disableElevation variant='contained' color='secondary'>
                        {t("sil")}
                    </Button>
                ]}
            >
                <b>{selectedDebitData?.user?.userName + " " + selectedDebitData?.user?.userSurName} </b>{t("kişisindekizimmetsilmekistediğinizdeneminmisiniz")}
            </CustomDialog>

            {/* CREATE ADD DEBIT PRODUCT DIALOG */}
            <CustomDialog
                maxWidth="sm"
                title={t("zimmetekle")}
                open={debitDialog}
                close={() => {
                    setDebitDialog(false)
                    setAddDebitData({})
                }}
                buttons={[
                    <Button key="cancel" onClick={() => {
                        setDebitDialog(false)
                        setAddDebitData({})
                    }}>{t("iptalet")}</Button>,
                    <Button key="save"/*  onClick={(i) => addDebitFunc(i)} */ disableElevation variant='contained' color='secondary'>
                        {t("ekle")}
                    </Button>
                ]}
            >

                {/*  <Autocomplete
                    options={assignedUsers}
                    renderInput={(params) => <TextField {...params} label={t("arızayapacakkişi")} variant='standard' fullWidth InputLabelProps={{ required: true }} />}
                    getOptionLabel={(option) => option.userName + ' ' + option.userSurName}
                    value={addDebitData?.user}
                    onChange={(event, newValue) => {
                        addDebitData.user = newValue
                        setAddDebitData({ ...addDebitData })
                    }}
                /> */}

                <Autocomplete
                    sx={{ marginTop: "16px" }}
                    options={items}
                    renderInput={(params) => <TextField {...params} label={t("malzemelistesi")} variant='standard' fullWidth InputLabelProps={{ required: true }} />}
                    getOptionLabel={(option) => option.title}
                    filterOptions={(options, { inputValue }) => options.filter(option => option.title.toLocaleUpperCase().includes(inputValue.toLocaleUpperCase()))}
                    value={addDebitData?.item}
                    onChange={(event, newValue) => {
                        addDebitData.item = newValue
                        setAddDebitData({ ...addDebitData })
                    }}
                />

                <TextField
                    type='number'
                    sx={{ marginTop: "16px" }}
                    label={t("adet")}
                    variant='standard'
                    fullWidth
                    value={addDebitData?.amount}
                    onChange={(e) => {
                        addDebitData.amount = e.target.value
                        setAddDebitData({ ...addDebitData })
                    }}
                />

            </CustomDialog>
        </Box >
    )

}

export default Page