import { CloseRounded } from "@mui/icons-material";
import { IconButton, Typography, Box, Dialog, DialogTitle, DialogContent, DialogActions, Divider } from "@mui/material"


const CustomDialog = ({ children, title, close, open, buttons, maxWidth }) => {

    return (
        <Dialog
            open={open}
            onClose={close}
            maxWidth={maxWidth}
            fullWidth
        >
            <DialogTitle>

                <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', gap: '.5rem' }}>
                    <Typography variant="overline" fontSize={15} lineHeight="unset">
                        {title}
                    </Typography>
                    <IconButton onClick={close}>
                        <CloseRounded />
                    </IconButton>
                </Box>
            </DialogTitle>
            <Divider />
            <DialogContent>
                {children}
            </DialogContent>
            {
                buttons &&
                <>
                    <Divider />


                    <DialogActions sx={{ px: 2 }}>
                        {buttons}
                    </DialogActions>
                </>
            }
        </Dialog >
    )
};

export default CustomDialog;