import { DatePicker, LoadingButton, LocalizationProvider, TimePicker } from "@mui/lab"
import AdapterDateFns from "@mui/lab/AdapterDateFns"
import { Paper, Typography, useTheme, Box, Badge, Button, Autocomplete, TextField, MenuItem, Grid } from "@mui/material"
import { format, getHours, getTime } from "date-fns"
import { tr } from "date-fns/locale"
import { useSnackbar } from "notistack"
import React, { useEffect, useState } from 'react'
import { useTranslation } from "react-i18next"
import NumberFormat from "react-number-format"
import { chargeRequest, payRequest } from "../../api/controllers/accounting-controller"
import { getEventHistoryByDateRequest, getUnReadRequest, markReadListRequest, markSeenRequest } from "../../api/controllers/event-history-controller"
import CustomDataGrid, { TableMenu } from "../../components/CustomDataGrid"
import CustomDialog from "../../components/CustomDialog"
import FilterListIcon from '@mui/icons-material/FilterList';




const Page = () => {
    const { t } = useTranslation("common");
    document.title = 'Aselmind | ' + t("bildirimler")
    const theme = useTheme()
    const { enqueueSnackbar } = useSnackbar();

    const [messageUnread, setMessageUnread] = useState([])
    const [selectedMessageUnread, setSelectedMessageUnread] = useState([])
    const [refLis, setRefLis] = useState([])
    const [filterDialog, setFilterDialog] = useState(false)
    const [date, setDate] = useState({
        start: format(new Date(), "yyyy-MM-dd"),
        end: format(new Date(), "yyyy-MM-dd"),
    })
    const [isDisabled, setIsDisabled] = useState(false)

    const columns = [
        {
            field: 'logDate',
            headerName: t('tarihvesaat'),
            minWidth: 150,
            flex: 2,
            renderCell: (params) => params?.row?.logDate ? <Typography sx={{ opacity: .6, fontSize: '14px' }}>{params?.row?.logDate} / {params?.row?.logTime}</Typography> : <Typography sx={{ opacity: .6, fontSize: '14px' }}>{t('belirtilmemiş')}</Typography>
        },
        {
            field: 'message',
            headerName: t('açıklama'),
            minWidth: 150,
            flex: 5,
            renderCell: (params) => params?.row?.message ? params?.row?.message : <Typography sx={{ opacity: .6, fontSize: '14px' }}>{t('belirtilmemiş')}</Typography>

        },
        {
            field: 'ref',
            headerName: <></>,
            minWidth: 50,
            type: 'actions',
            align: 'right',
            flex: 1,
            renderCell: (params) => <TableMenu
                menuItems={
                    [
                        <MenuItem key={"edit"} /* onClick={() => DebtFunc(params?.row)} */>{t("okunduolarakişaretle")}</MenuItem>,
                        /*    <MenuItem key={"delete"} onClick={() => CollectionFunc(params?.row)}>{t("tahsilat")}</MenuItem> */
                    ]
                }
            />
        },
    ]

    //GET UNREAD MESSAGE
    const fetchUnreadMessage = async () => {
        try {
            let res = await getUnReadRequest()
            if (res) {
                setMessageUnread(res?.data)
            }
        } catch (error) {
            console.log(error)
        }
    }

    // RESET NOTIFICATION REED

    const notificationSeen = async () => {

        try {
            let res = await markSeenRequest()

        } catch (error) {

        }
    }

    useEffect(() => {
        fetchUnreadMessage()
        notificationSeen()

    }, [])

    const handleAllEventRead = async () => {
        let res = await markReadListRequest({ idList: messageUnread.map(v => v.ref) })
        console.log('res', res)
        if (res?.status === 200) {
            fetchUnreadMessage()
        }

    }
    const closeFilterDialog = () => {
        setFilterDialog(false)
    }
    const getFilterEvent = async () => {
        setIsDisabled(true)
        try {
            let res = await getEventHistoryByDateRequest({
                localDate: date.start
            })
            console.log('res', res)
            if (res) {
                setIsDisabled(false)
                setMessageUnread(res?.data)
                setFilterDialog(false)
            }
        } catch (error) {
            setIsDisabled(false)

        }
    }
    const filterClean = () => {
        fetchUnreadMessage()
    }
    return (
        <Box p={3}>

            <Paper
                elevation={0}
                sx={{
                    display: "flex",
                    padding: theme.spacing(2),
                    alignItems: "center",
                    justifyContent: "space-between",
                    flexDirection: { xs: 'column', sm: 'column', md: 'row', lg: "row" }
                }}
            >
                <Typography sx={{ fontSize: window.innerWidth < 675 ? "1.75rem" : "2.125rem" }} variant="h4">{t('bildirimler')}</Typography>
                <Button variant="contained" onClick={handleAllEventRead} color={"secondary"}>
                    {t('tumbildirimleriokunduolarakişaretle')}
                </Button>
            </Paper>
            <Paper
                elevation={0}
                sx={{
                    padding: theme.spacing(2),
                    margin: theme.spacing(2, 0),
                }}
            >
                <Button
                    onClick={(e) => {
                        setFilterDialog(true)
                    }}
                    variant="outlined"
                    color="primary"
                    endIcon={<FilterListIcon />}
                    sx={{
                        minHeight: 40,
                        margin: "8px"
                    }}
                >
                    <div>{t("filtrele")}</div>
                </Button>
                <Button
                    onClick={(e) => {
                        filterClean()
                    }}
                    variant="contained"
                    color="secondary"
                    sx={{
                        minHeight: 40,
                        margin: "8px"
                    }}
                >
                    <div>{t("filtretemizle")}</div>
                </Button>
                <CustomDataGrid
                    columns={columns}
                    data={messageUnread}
                    rowId={(params) => params?.ref}
                    selectedRows={(params) => setSelectedMessageUnread(params)}
                />

            </Paper>
            <CustomDialog
                maxWidth="xs"
                title={t("filtreleme")}
                open={filterDialog}
                close={closeFilterDialog}
                buttons={[
                    <Button key="cancel" onClick={() => closeFilterDialog()}>{t("iptalet")}</Button>,
                    <LoadingButton loading={isDisabled} key="save" onClick={() => getFilterEvent()} disableElevation variant='contained' color='secondary'>
                        {t("raporla")}
                    </LoadingButton>
                ]}
            >
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={12} >
                        <LocalizationProvider dateAdapter={AdapterDateFns} locale={tr}>
                            <DatePicker
                                label={t("başlangıçtarihi")}
                                value={date?.start}
                                onChange={(newValue) => {
                                    date.start = format(newValue, 'yyyy-MM-dd')
                                    setDate({ ...date })
                                }}
                                renderInput={(params) => <TextField {...params} variant='standard' fullWidth />}
                            />
                        </LocalizationProvider>
                    </Grid>

                </Grid>
            </CustomDialog>
        </Box>
    )

}

export default Page