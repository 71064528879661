import { makeStyles } from '@mui/styles';
import { useTheme } from '@mui/material/styles';
import { Menu as MenuIcon, ChevronLeft, ExpandLess, ExpandMore, Dashboard, FormatListBulleted, NotificationsActiveOutlined, AccountCircleRounded, Close, TuneRounded, AssessmentOutlined, AccountBox, People, Settings, ExitToApp } from '@mui/icons-material';
import { ListItemText, ListItemIcon, ListItem, IconButton, Divider, List, Toolbar, Box, Collapse, Fade, Tabs, Paper, Button, Grid, Typography, Tab, MenuItem, Menu, Accordion, AccordionSummary, AccordionDetails, Modal, TextField, FormControl, InputLabel, Select, Chip } from '@mui/material';
import { Link, useLocation } from 'react-router-dom';
import { useEffect, useState, Fragment } from 'react';
import DrawerHeader from './DrawerHeader';
import Drawer from './Drawer';
import AppBar from './AppBar';
import { GridExpandMoreIcon } from '@mui/x-data-grid';
import CircleIcon from '@mui/icons-material/Circle';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { getCurrentUserRequest, logoutRequest } from "../api/controllers/home-controller"
import ApartmentIcon from '@mui/icons-material/Apartment';
import WarehouseIcon from '@mui/icons-material/Warehouse';
import FactoryIcon from '@mui/icons-material/Factory';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import CalculateIcon from '@mui/icons-material/Calculate';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import EngineeringIcon from '@mui/icons-material/Engineering';
import WarningIcon from '@mui/icons-material/Warning';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import AssessmentIcon from '@mui/icons-material/Assessment';
import HandymanIcon from '@mui/icons-material/Handyman';
import TranslateIcon from '@mui/icons-material/Translate';
import CustomDialog from './CustomDialog';
import i18n from '../i18n';
import { getNewEventHistoryCountRequest, markSeenRequest } from '../api/controllers/event-history-controller';
import LogoutIcon from '@mui/icons-material/Logout';
export const drawerWidth = 300;


const CustomLayout = ({ children }) => {
    const { t } = useTranslation("common");
    const history = useHistory();
    const theme = useTheme();
    const [notificationsOpen, setNotificationsOpen] = useState(false)
    const [menuOpen, setMenuOpen] = useState(false)
    const [anchorEl, setAnchorEl] = useState(null)
    const [tabValue, setTabValue] = useState("okunmamis-bildirimler")

    const [userData, setUserData] = useState()

    const [notificationCount, setNotificationCount] = useState(0)
    const [isOpen, setIsOpen] = useState(false)


    const handleLangClose = () => {
        setIsOpen(false)
    }

    const openLangModal = () => {
        setIsOpen(true)
    }

    const notificationSeen = async () => {

        try {
            let res = await markSeenRequest()
            if (res) {
                getNotificationCount()
            }
        } catch (error) {

        }
    }

    const getNotificationCount = async () => {

        try {
            let res = await getNewEventHistoryCountRequest()
            if (res) {
                setNotificationCount(res.data)

            }
        } catch (error) {

        }
    }

    const fetchCurrentUser = async () => {
        let res = await getCurrentUserRequest()
        if (res) {
            setUserData(res?.data)
        }
    }

    const useStyles = makeStyles({
        appbarRoot: {
            "&::after": {
                position: "absolute",
                content: '""',
                width: "72px",
                height: "8px",
                top: "48px",
                backgroundColor: theme.palette.background.paper,
                [theme.breakpoints.down("xs")]: {
                    top: "56px"
                },
                "@media (orientation: landscape)": {
                    top: "48px"
                },
                [theme.breakpoints.up("sm")]: {
                    top: "64px"
                }
            }
        },
        toolbarRoot: {
            backgroundColor: theme.palette.background.paper,
            color: theme.palette.primary.main,
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between"
        },
        notificationsBubble: {
            position: "absolute",
            // backgroundColor: theme.palette.background.paper,
            zIndex: 99999,
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
            [theme.breakpoints.down("sm")]: {
                top: 0,
                left: 0,
                width: "100vw",
                height: "100vh",
                padding: theme.spacing(3)
            },
            [theme.breakpoints.up('md')]: {
                right: 10,
                top: 50,
                borderRadius: 10,
                padding: theme.spacing(3),
                minWidth: drawerWidth + 100,
            },
        }
    })

    const classes = useStyles()

    const TabPanel = (props) => props?.value === tabValue
        ? <Box>{props?.children}</Box>
        : <></>
    const [drawerOpen, setDrawerOpen] = useState(window.innerWidth < 675 ? false : true)
    const handleDrawerToggle = () => {
        setDrawerOpen(!drawerOpen)
    };

    useEffect(() => {
        fetchCurrentUser()
        layoutSetting()

    }, [])




    const [isActive, setIsActive] = useState(false)
    const [isActive2, setIsActive2] = useState(false)
    const [isActive3, setIsActive3] = useState(false)
    const [expanded, setExpanded] = useState(false);

    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
        setIsActive(false)
        setIsActive2(false)
        setIsActive3(false)
    };

    let path = window.location.pathname + window.location.search;
    let pathShort = window.location.pathname


    const layoutSetting = () => {

        if (path === '/') {
            setIsActive(false)
            setIsActive2(false)
            setIsActive3(false)
        }
        else if (path === '/tanimlar/binalar' || path === '/tanimlar/depolar' || path === '/tanimlar/tesisler' || path === '/tanimlar/araclar' || path === '/tanimlar/malzeme-listesi' || path === '/tanimlar/bina-ekle' || pathShort === '/tanimlar/bina-duzenle' || path === '/tanimlar/demirbas-listesi') {
            setExpanded('panel1')
            setIsActive(true)
        }
        else if (path === '/islemler/takip' || path === '/islemler/ariza-emirleri' || path === '/islemler/bakim-emirleri') {
            setExpanded('panel2')
            setIsActive2(true)
        }
        else if (path === '/raporlar/bitmis-arizalar' || pathShort === "/raporlar/bitmis-arizalar-detay" || path === '/raporlar/bitmis-bakimlar' || pathShort === "/raporlar/bitmis-bakimlar-detay" || path === '/raporlar/aylik-bakim-raporu' || path === '/raporlar/aylik-malzeme-raporu') {
            setExpanded('panel3')
            setIsActive3(true)
        }
        else {
        }
    }


    useEffect(async () => {

        await getNotificationCount()

    }, [])


    return (
        <Box sx={{ display: 'flex' }}>
            <AppBar
                position="fixed"
                open={drawerOpen}
                elevation={1}
                classes={{ root: !drawerOpen && classes.appbarRoot }}
            >
                <Toolbar classes={{ root: classes.toolbarRoot }}>
                    <IconButton
                        onClick={handleDrawerToggle}
                        edge="start"
                        sx={{
                            marginRight: '36px',
                        }}
                    >
                        {
                            drawerOpen ? <ChevronLeft /> : <MenuIcon />
                        }

                    </IconButton>
                    {
                        window.innerWidth < 675 && !drawerOpen &&

                        <Link to="/islemler/harita">
                            <img src="/assets/logo.svg" style={{ height: "auto", width: "100%", paddingRight: "10px" }} alt="Logo" />
                        </Link>
                    }
                    {
                        ((!drawerOpen && window.innerWidth < 675) || (window.innerWidth >= 675)) &&
                        <div
                            onClick={notificationSeen}
                            style={{
                                marginRight: window.innerWidth < 675 && 10,
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "space-between"
                            }}>

                            <div
                                /*  onClickCapture={() => setNotificationsOpen(true)}
                                 onMouseLeave={() => setNotificationsOpen(false)} */
                                style={{
                                    marginRight: theme.spacing(2),
                                    display: "flex",
                                    flexDirection: "row",
                                    alignItems: "center",
                                    position: "relative"
                                }}

                            >
                                <Link to="/kullanici/bildirimler">
                                    <IconButton
                                        sx={{
                                            backgroundColor: theme.palette.primary.main,
                                            color: theme.palette.primary.contrastText,
                                            borderRadius: 1,
                                            '&:hover': {
                                                backgroundColor: "darkgrey",
                                            }
                                        }}
                                    >
                                        <NotificationsActiveOutlined />
                                    </IconButton>
                                </Link>
                                {notificationCount > 0 ?
                                    <Link to="/kullanici/bildirimler">
                                        <div style={{ display: "flex", alignItems: "center", justifyContent: "center", position: "absolute", top: "-5px", right: "-7px", minWidth: "18px", color: "white", backgroundColor: theme.palette.secondary.main, borderRadius: "4px", padding: "0 3px", fontSize: "10px", cursor: "pointer" }}>
                                            {notificationCount}
                                        </div>
                                    </Link>
                                    : <></>
                                }

                                {/* <Fade
                                    in={notificationsOpen}
                                    unmountOnExit
                                >
                                    <Paper
                                        elevation={3}
                                        classes={{ root: classes.notificationsBubble }}
                                    >
                                        <Grid
                                            container
                                            justifyContent="space-between"
                                            alignItems="center"
                                            sx={{ display: { xs: 'flex', sm: 'flex', md: 'none', lg: "none" } }}
                                        >
                                            <Typography variant="h5">{t("bildirimler")}</Typography>
                                            <IconButton
                                                onClick={() => setNotificationsOpen(false)}
                                                sx={{
                                                    backgroundColor: theme.palette.primary.main,
                                                    color: theme.palette.primary.contrastText,
                                                }}
                                            >
                                                <Close />
                                            </IconButton>
                                        </Grid>

                                        <Box sx={{ borderBottom: 1, borderColor: 'divider', width: "100%" }}>
                                            <Tabs textColor="secondary" indicatorColor="secondary" value={tabValue} onChange={(event, newValue) => setTabValue(newValue)} sx={{ width: "100%", }}>
                                                <Tab value={"okunmamis-bildirimler"} sx={{ width: "50%" }} label={t("okunmamış")} />
                                                <Tab value={"tum-bildirimler"} sx={{ width: "50%" }} label={t("tümü")} />
                                            </Tabs>
                                        </Box>
                                        <TabPanel value={"okunmamis-bildirimler"}>
                                            Item One
                                        </TabPanel>
                                        <TabPanel value={"tum-bildirimler"}>
                                            Item Two
                                        </TabPanel>
                                    </Paper>
                                </Fade> */}
                            </div>



                            <Button
                                onClick={(e) => {
                                    setAnchorEl(e.currentTarget)
                                    setMenuOpen(true);
                                }}
                                startIcon={<AccountCircleRounded />}
                                variant="outlined"
                                color="primary"
                                endIcon={<ExpandMore />}
                                sx={{
                                    minHeight: 40,
                                }}
                            >
                                {
                                    window.innerWidth >= 675 &&
                                    <div style={{ paddingRight: "10px" }}>{userData?.userName}</div>
                                }
                                {
                                    window.innerWidth >= 675 &&
                                    <div>{userData?.userSurName}</div>
                                }

                            </Button>

                            <Menu
                                anchorEl={anchorEl}
                                open={menuOpen}
                                onClose={() => {
                                    setMenuOpen(false)
                                    setAnchorEl(null)
                                }}
                                TransitionComponent={Fade}
                                PaperProps={{
                                    elevation: 3,
                                    sx: {
                                        width: "auto",
                                        minWidth: anchorEl?.offsetWidth || "auto",
                                    }
                                }}
                            >
                                <Link to="/kullanici/hesabim">
                                    <MenuItem>
                                        <ListItemIcon>
                                            <AccountBox fontSize="small" />
                                        </ListItemIcon>
                                        <ListItemText>{t("hesabım")}</ListItemText>
                                    </MenuItem>
                                </Link>
                                {/* <Link to="/kullanici/kullanicilar">
                                    <MenuItem>
                                        <ListItemIcon>
                                            <People fontSize="small" />
                                        </ListItemIcon>
                                        <ListItemText>{t("kullanıcılar")}</ListItemText>
                                    </MenuItem>
                                </Link> */}
                                <Link to="/kullanici/ayarlar">
                                    <MenuItem>
                                        <ListItemIcon>
                                            <Settings fontSize="small" />
                                        </ListItemIcon>
                                        <ListItemText>{t("ayarlar")}</ListItemText>
                                    </MenuItem>
                                </Link>
                                {/*  <Link to="/kullanici/bildirimler">
                                    <MenuItem>
                                        <ListItemIcon>
                                            <NotificationsActiveOutlined fontSize="small" />
                                        </ListItemIcon>
                                        <ListItemText>{t("bildirimler")}</ListItemText>
                                    </MenuItem>
                                </Link> */}
                                {/* <div onClick={openLangModal}>
                                    <MenuItem>
                                        <ListItemIcon>
                                            <TranslateIcon fontSize="small" />
                                        </ListItemIcon>
                                        <ListItemText>{t("dil")}</ListItemText>
                                    </MenuItem>
                                </div> */}
                                <Divider />
                                <Link to="/support">
                                    <MenuItem onClick={() => logoutRequest()}>
                                        <ListItemIcon>
                                            <img src='/assets/questionMarkDark.svg' />
                                        </ListItemIcon>
                                        <ListItemText>{t("destek")}</ListItemText>
                                    </MenuItem>
                                </Link>
                                <Link to="/login">
                                    <MenuItem onClick={() => logoutRequest()}>
                                        <ListItemIcon>
                                            <LogoutIcon fontSize="small" />
                                        </ListItemIcon>
                                        <ListItemText>{t("çıkışyap")}</ListItemText>
                                    </MenuItem>
                                </Link>
                            </Menu>
                        </div>
                    }

                </Toolbar>
            </AppBar>
            <Drawer variant="permanent" open={drawerOpen} PaperProps={{
                elevation: 1
            }} >
                <DrawerHeader>
                    <Link to="/islemler/harita" style={{ display: "flex", justifyContent: "center" }}>
                        <img src="/assets/logo.svg" style={{ height: "auto", width: "75%" }} alt="Logo" />
                    </Link>

                </DrawerHeader>
                <Divider />
                <List sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between",
                    height: "100%",
                    padding: drawerOpen ? theme.spacing(0, 3) : 0
                }}>

                    <Box className='asd1234' sx={{ display: "flex", flexDirection: "column", }}>

                        {/* ANA SAYFA */}
                        {/* <Box sx={{ margin: "8px 0" }}>
                            <Button className='layout-button' aria-label="delete" >
                                <Link className='layout-button-link' to='/' >
                                    <Box onClick={handleChange('panel4')}
                                        sx={{
                                            display: "flex", width: "100%", flexDirection: "row", padding: "12px 8px", borderRadius: "12px", margin: "8px 0", backgroundColor: path === "/" ? "rgba(77, 77, 77, 1)" : "white",
                                            '&:hover': {
                                                backgroundColor: path === "/" ? 'rgba(77, 77, 77, 1)' : 'rgba(77, 77, 77,0.05)',
                                            }
                                        }}>
                                        <Dashboard sx={{ marginRight: "10px", marginLeft: drawerOpen ? 0 : window.innerWidth < 675 ? 0 : "8px", color: path === "/" ? "white" : "rgba(77, 77, 77, 1)" }} />
                                        {drawerOpen ? <Typography sx={{ textTransform: "capitalize", color: path === "/" ? "white" : "rgba(77, 77, 77, 1)" }}>{t("anasayfa")}</Typography> : <></>}
                                    </Box>
                                </Link>
                            </Button>
                        </Box> */}

                        {/* HARİTA */}
                        <Box sx={{ margin: "8px 0" }}>
                            <Button className='layout-button' aria-label="delete" >
                                <Link className='layout-button-link' to='/islemler/harita' >
                                    <Box onClick={{}}
                                        sx={{
                                            display: "flex", justifyContent: drawerOpen ? "flex-start" : "center", alignItems: "center", width: "100%", flexDirection: "row", padding: "12px 8px", borderRadius: "12px", margin: "8px 0", backgroundColor: path === "/islemler/harita" ? "rgba(77, 77, 77, 1)" : "white",
                                            '&:hover': {
                                                backgroundColor: path === "/islemler/harita" ? 'rgba(77, 77, 77, 1)' : 'rgba(77, 77, 77,0.05)',
                                            }
                                        }}>
                                        {/* <Dashboard sx={{ marginRight: "10px", marginLeft: drawerOpen ? 0 : window.innerWidth < 675 ? 0 : "8px", color: path === "/islemler/harita" ? "white" : "rgba(77, 77, 77, 1)" }} /> */}
                                        {path === "/islemler/harita" ? <img src='/assets/canlitakip.svg' style={{ width: "24px", marginRight: drawerOpen ? "8px" : "0px" }} /> : <img src='/assets/canlitakipgrey.svg' style={{ width: "24px", marginRight: drawerOpen ? "8px" : "0px" }} />}
                                        {drawerOpen ? <Typography sx={{ textTransform: "capitalize", color: path === "/islemler/harita" ? "white" : "rgba(77, 77, 77, 1)" }}>{t("harita")}</Typography> : <></>}
                                    </Box>
                                </Link>
                            </Button>
                        </Box>


                        {/* MUASEBE */}
                        <Box sx={{ margin: "8px 0" }}>
                            <Button className='layout-button' aria-label="delete" >
                                <Link className='layout-button-link' to='/islemler/muhasebe' >
                                    <Box onClick={{}}
                                        sx={{
                                            display: "flex", justifyContent: drawerOpen ? "flex-start" : "center", alignItems: "center", width: "100%", flexDirection: "row", padding: "12px 8px", borderRadius: "12px", margin: "8px 0", backgroundColor: pathShort === "/islemler/muhasebe" ? "rgba(77, 77, 77, 1)" : "white",
                                            '&:hover': {
                                                backgroundColor: pathShort === "/islemler/muhasebe" ? 'rgba(77, 77, 77, 1)' : 'rgba(77, 77, 77,0.05)',
                                            }
                                        }}>
                                        <CalculateIcon sx={{ width: "20px", marginRight: drawerOpen ? "8px" : "0px", color: pathShort === "/islemler/muhasebe" ? "white" : "rgba(77, 77, 77, 1)" }} />
                                        {drawerOpen ? <Typography sx={{ textTransform: "capitalize", color: pathShort === "/islemler/muhasebe" ? "white" : "rgba(77, 77, 77, 1)" }}>{t("muhasebe")}</Typography> : <></>}
                                    </Box>
                                </Link>
                            </Button>
                        </Box>

                        {/* KULLANICILAR    */}
                        <Box sx={{ margin: "8px 0" }}>
                            <Button className='layout-button' aria-label="delete" >
                                <Link className='layout-button-link' to='/kullanici/kullanicilar' >
                                    <Box onClick={{}}
                                        sx={{
                                            display: "flex", justifyContent: drawerOpen ? "flex-start" : "center", alignItems: "center", width: "100%", flexDirection: "row", padding: "12px 8px", borderRadius: "12px", margin: "8px 0", backgroundColor: pathShort === "/kullanici/kullanicilar" ? "rgba(77, 77, 77, 1)" : "white",
                                            '&:hover': {
                                                backgroundColor: pathShort === "/kullanici/kullanicilar" ? 'rgba(77, 77, 77, 1)' : 'rgba(77, 77, 77,0.05)',
                                            }
                                        }}>
                                        <People sx={{ width: "20px", marginRight: drawerOpen ? "8px" : "0px", color: pathShort === "/kullanici/kullanicilar" ? "white" : "rgba(77, 77, 77, 1)" }} />
                                        {drawerOpen ? <Typography sx={{ textTransform: "capitalize", color: pathShort === "/kullanici/kullanicilar" ? "white" : "rgba(77, 77, 77, 1)" }}>{t("kullanıcılar")}</Typography> : <></>}
                                    </Box>
                                </Link>
                            </Button>
                        </Box>

                        {/*TANIMLAR*/}
                        <Button className='tanimlar-button' >
                            <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')} className='asd123' sx={{ boxShadow: "none", backgroundColor: isActive ? "rgba(77, 77, 77, 1)" : "white", }}>
                                <AccordionSummary
                                    onClick={() => { setIsActive(!isActive); setIsActive2(false); setIsActive3(false); }}
                                    sx={{
                                        borderRadius: "12px",
                                        padding: "0 8px", '&:hover': {
                                            backgroundColor: 'rgba(77, 77, 77,0.05)',
                                        }, margin: "0px",
                                    }}
                                    expandIcon={drawerOpen ? <GridExpandMoreIcon sx={{ color: isActive ? "white" : "rgba(77, 77, 77, 1)" }} /> : <></>}
                                    aria-controls="panel1a-content"
                                    id="panel1a-header"
                                >
                                    <Box sx={{ display: "flex", flexDirection: "row", }}>
                                        <FormatListBulleted sx={{ marginRight: drawerOpen ? "10px" : 0, marginLeft: drawerOpen ? 0 : window.innerWidth < 675 ? 0 : "8px", color: isActive ? "white" : "rgba(77, 77, 77, 1)" }} />
                                        {drawerOpen ? <Typography sx={{ color: isActive ? "white" : "rgba(77, 77, 77, 1)" }}>{t("tanımlar")}</Typography> : <></>}
                                    </Box>
                                </AccordionSummary>

                                <AccordionDetails sx={{ padding: drawerOpen ? " 0 16px" : "2px" }}>

                                    {drawerOpen ?
                                        <Box sx={{ display: "flex" }}>
                                            <Typography sx={{ color: "white", fontSize: "14px", fontWeight: "200" }}>
                                                {t("asansörler/araçlar")}
                                            </Typography>
                                        </Box> : <></>}

                                    <Divider className='dvider_accordion' />

                                    <Box onClick={() => { path === "/tanimlar/binalar" ? <></> : history.push("/tanimlar/binalar") }}
                                        sx={{
                                            display: "flex", flexDirection: "row", padding: window.innerWidth > 675 ? "12px 16px" : "8px", margin: "8px 0", borderRadius: "12px",
                                            backgroundColor: path === "/tanimlar/binalar" || path === "/tanimlar/bina-ekle" || pathShort === "/tanimlar/bina-duzenle" ? "white" : "",
                                            cursor: path === "/tanimlar/binalar" || path === "/tanimlar/bina-ekle" || pathShort === "/tanimlar/bina-duzenle" ? "default" : "pointer",
                                            '&:hover': {
                                                backgroundColor: path === "/tanimlar/binalar" || path === "/tanimlar/bina-ekle" || pathShort === "/tanimlar/bina-duzenle" ? "" : "rgba(71, 70, 70, 0.6)",
                                            }
                                        }}>
                                        <ApartmentIcon sx={{ width: "20px", marginRight: "8px", color: path === "/tanimlar/binalar" || path === "/tanimlar/bina-ekle" || pathShort === "/tanimlar/bina-duzenle" && isActive ? "rgba(77, 77, 77, 1)" : "white" }} />
                                        {drawerOpen ? <Typography sx={{ color: path === "/tanimlar/binalar" || path === "/tanimlar/bina-ekle" || pathShort === "/tanimlar/bina-duzenle" && isActive ? "rgba(77, 77, 77, 1)" : "white" }}>{t("binalar")}</Typography> : <></>}
                                    </Box>

                                    <Box onClick={() => { path === "/tanimlar/gruplar" ? <></> : history.push("/tanimlar/gruplar") }}
                                        sx={{
                                            display: "flex", flexDirection: "row", padding: window.innerWidth > 675 ? "12px 16px" : "8px", margin: "8px 0", borderRadius: "12px",
                                            backgroundColor: path === "/tanimlar/gruplar" ? "white" : "",
                                            cursor: path === "/tanimlar/gruplar" ? "default" : "pointer",
                                            '&:hover': {
                                                backgroundColor: path === "/tanimlar/gruplar" ? "" : "rgba(71, 70, 70, 0.6)",
                                            }
                                        }}>
                                        {path === "/tanimlar/gruplar" ? <img src='/assets/buildingsgrey.svg' style={{ width: "18px", marginRight: drawerOpen ? "8px" : "0px" }} /> : <img src='/assets/buildings.svg' style={{ width: "18px", marginRight: drawerOpen ? "8px" : "0px" }} />}
                                        {/* <ApartmentIcon sx={{ width: "20px", marginRight: "8px", color: path === "/tanimlar/gruplar" || path === "/tanimlar/grup-ekle" || pathShort === "/tanimlar/grup-duzenle" && isActive ? "rgba(77, 77, 77, 1)" : "white" }} /> */}
                                        {drawerOpen ? <Typography sx={{ color: path === "/tanimlar/gruplar" && isActive ? "rgba(77, 77, 77, 1)" : "white" }}>{t("gruplar")}</Typography> : <></>}
                                    </Box>

                                    <Box onClick={() => { path === "/tanimlar/depolar" ? <></> : history.push("/tanimlar/depolar") }}
                                        sx={{
                                            display: "flex", flexDirection: "row", padding: window.innerWidth > 675 ? "12px 16px" : "8px", margin: "8px 0", borderRadius: "12px",
                                            backgroundColor: path === "/tanimlar/depolar" ? "white" : "",
                                            cursor: path === "/tanimlar/depolar" ? "default" : "pointer",
                                            '&:hover': {
                                                backgroundColor: path === "/tanimlar/depolar" ? "" : "rgba(71, 70, 70, 0.6)",
                                            }
                                        }}>

                                        <WarehouseIcon sx={{ width: "20px", marginRight: "8px", color: path === "/tanimlar/depolar" && isActive ? "rgba(77, 77, 77, 1)" : "white" }} />
                                        {drawerOpen ? <Typography sx={{ color: path === "/tanimlar/depolar" && isActive ? "rgba(77, 77, 77, 1)" : "white" }}>{t("depolar")}</Typography> : <></>}
                                    </Box>

                                    <Box onClick={() => { path === "/tanimlar/tesisler" ? <></> : history.push("/tanimlar/tesisler") }}
                                        sx={{
                                            display: "flex", flexDirection: "row", padding: window.innerWidth > 675 ? "12px 16px" : "8px", margin: "8px 0", borderRadius: "12px",
                                            backgroundColor: path === "/tanimlar/tesisler" ? "white" : "",
                                            cursor: path === "/tanimlar/tesisler" ? "default" : "pointer",
                                            '&:hover': {
                                                backgroundColor: path === "/tanimlar/tesisler" ? "" : "rgba(71, 70, 70, 0.6)",
                                            }
                                        }}>

                                        <FactoryIcon sx={{ width: "20px", marginRight: "8px", color: path === "/tanimlar/tesisler" && isActive ? "rgba(77, 77, 77, 1)" : "white" }} />
                                        {drawerOpen ? <Typography sx={{ color: path === "/tanimlar/tesisler" && isActive ? "rgba(77, 77, 77, 1)" : "white" }}>{t("tesisler")}</Typography> : <></>}
                                    </Box>

                                    <Box onClick={() => { path === "/tanimlar/araclar" ? <></> : history.push("/tanimlar/araclar") }}
                                        sx={{
                                            display: "flex", flexDirection: "row", padding: window.innerWidth > 675 ? "12px 16px" : "8px", margin: "8px 0", borderRadius: "12px",
                                            backgroundColor: path === "/tanimlar/araclar" ? "white" : "",
                                            cursor: path === "/tanimlar/araclar" ? "default" : "pointer",
                                            '&:hover': {
                                                backgroundColor: path === "/tanimlar/araclar" ? "" : "rgba(71, 70, 70, 0.6)",
                                            }
                                        }}>

                                        <LocalShippingIcon sx={{ width: "20px", marginRight: "8px", color: path === "/tanimlar/araclar" && isActive ? "rgba(77, 77, 77, 1)" : "white" }} />
                                        {drawerOpen ? <Typography sx={{ color: path === "/tanimlar/araclar" && isActive ? "rgba(77, 77, 77, 1)" : "white" }}>{t("araçlar")}</Typography> : <></>}
                                    </Box>

                                    {drawerOpen ? <Box sx={{ display: "flex" }}>
                                        <Typography sx={{ color: "white", fontSize: "14px", fontWeight: "200" }}>
                                            {t("muhasebe")}
                                        </Typography>
                                    </Box>
                                        : <></>}

                                    <Divider className='dvider_accordion' />

                                    <Box onClick={() => { path === "/tanimlar/malzeme-listesi" ? <></> : history.push("/tanimlar/malzeme-listesi") }}
                                        sx={{
                                            display: "flex", flexDirection: "row", padding: window.innerWidth > 675 ? "12px 16px" : "8px", margin: drawerOpen ? "8px 0" : 0, borderRadius: "12px",
                                            backgroundColor: path === "/tanimlar/malzeme-listesi" ? "white" : "",
                                            cursor: path === "/tanimlar/malzeme-listesi" ? "default" : "pointer",
                                            '&:hover': {
                                                backgroundColor: path === "/tanimlar/malzeme-listesi" ? "" : "rgba(71, 70, 70, 0.6)",
                                            }
                                        }}>

                                        <HandymanIcon sx={{ width: "20px", marginRight: "8px", color: path === "/tanimlar/malzeme-listesi" && isActive ? "rgba(77, 77, 77, 1)" : "white" }} />
                                        {drawerOpen ? <Typography sx={{ color: path === "/tanimlar/malzeme-listesi" && isActive ? "rgba(77, 77, 77, 1)" : "white" }}>{t("malzemelistesi")}</Typography> : <></>}
                                    </Box>

                                    <Box onClick={() => { path === "/tanimlar/demirbas-listesi" ? <></> : history.push("/tanimlar/demirbas-listesi") }}
                                        sx={{
                                            display: "flex", flexDirection: "row", padding: window.innerWidth > 675 ? "12px 16px" : "8px", margin: drawerOpen ? "8px 0" : 0, borderRadius: "12px",
                                            backgroundColor: path === "/tanimlar/demirbas-listesi" ? "white" : "",
                                            cursor: path === "/tanimlar/demirbas-listesi" ? "default" : "pointer",
                                            '&:hover': {
                                                backgroundColor: path === "/tanimlar/demirbas-listesi" ? "" : "rgba(71, 70, 70, 0.6)",
                                            }
                                        }}>

                                        <HandymanIcon sx={{ width: "20px", marginRight: "8px", color: path === "/tanimlar/demirbas-listesi" && isActive ? "rgba(77, 77, 77, 1)" : "white" }} />
                                        {drawerOpen ? <Typography sx={{ color: path === "/tanimlar/demirbas-listesi" && isActive ? "rgba(77, 77, 77, 1)" : "white" }}>{t("demirbaşlistesi")}</Typography> : <></>}
                                    </Box>

                                </AccordionDetails>
                            </Accordion>
                        </Button>
                        <Divider sx={{ margin: "4px 0", borderColor: "white" }} />

                        {/*İŞLEMLER*/}
                        <Button className='islemler-button'>
                            <Accordion expanded={expanded === 'panel2'} onChange={handleChange('panel2')} className='asd123' sx={{ boxShadow: "none", backgroundColor: isActive2 ? "rgba(77, 77, 77, 1)" : "white", }}>
                                <AccordionSummary
                                    onClick={() => { setIsActive(false); setIsActive2(!isActive2); setIsActive3(false); }}
                                    sx={{
                                        borderRadius: "12px",
                                        padding: "0 8px", '&:hover': {
                                            backgroundColor: 'rgba(77, 77, 77,0.05)',
                                        }, margin: "0px",
                                    }}
                                    expandIcon={drawerOpen ? <GridExpandMoreIcon sx={{ color: isActive2 ? "white" : "rgba(77, 77, 77, 1)" }} /> : <></>}
                                    aria-controls="panel1a-content"
                                    id="panel1a-header"
                                >
                                    <Box sx={{ display: "flex", flexDirection: "row", }}>
                                        <TuneRounded sx={{ marginRight: drawerOpen ? "10px" : 0, marginLeft: drawerOpen ? 0 : window.innerWidth < 675 ? 0 : "8px", color: isActive2 ? "white" : "rgba(77, 77, 77, 1)" }} />
                                        {drawerOpen ? <Typography sx={{ color: isActive2 ? "white" : "rgba(77, 77, 77, 1)" }}>{t("işlemler")}</Typography> : <></>}
                                    </Box>
                                </AccordionSummary>

                                <AccordionDetails sx={{ padding: drawerOpen ? " 0 16px" : "2px" }}>

                                    {/*  <Box onClick={() => { path === "/islemler/muhasebe" ? <></> : history.push("/islemler/muhasebe") }}
                                        sx={{
                                            display: "flex", flexDirection: "row", padding: window.innerWidth > 675 ? "12px 16px" : "8px", margin: "8px 0", borderRadius: "12px",
                                            backgroundColor: path === "/islemler/muhasebe" ? "white" : "",
                                            cursor: path === "/islemler/muhasebe" ? "default" : "pointer",
                                            '&:hover': {
                                                backgroundColor: path === "/islemler/muhasebe" ? "" : "rgba(71, 70, 70, 0.6)",
                                            }
                                        }}>
                                        <CalculateIcon sx={{ width: "20px", marginRight: "8px", color: path === "/islemler/muhasebe" && isActive2 ? "rgba(77, 77, 77, 1)" : "white" }} />
                                        {drawerOpen ? <Typography sx={{ color: path === "/islemler/muhasebe" && isActive2 ? "rgba(77, 77, 77, 1)" : "white" }}>{t("muhasebe")}</Typography> : <></>}
                                    </Box> */}

                                    {/* <Box onClick={() => { path === "/tanimlar/harita" ? <></> : history.push("/islemler/harita") }}
                                        sx={{
                                            display: "flex", flexDirection: "row", padding: window.innerWidth > 675 ? "12px 16px" : "8px", margin: "8px 0", borderRadius: "12px",
                                            backgroundColor: path === "/islemler/harita" ? "white" : "",
                                            cursor: path === "/islemler/harita" ? "default" : "pointer",
                                            '&:hover': {
                                                backgroundColor: path === "/islemler/harita" ? "" : "rgba(71, 70, 70, 0.6)",
                                            }
                                        }}>
                                        {path === "/islemler/harita" && isActive2 ? <img src='/assets/canlitakipgrey.svg' style={{ width: "20px", marginRight: "8px" }} />
                                            : <img src='/assets/canlitakip.svg' style={{ width: "20px", marginRight: "8px" }} />}
                                        {drawerOpen ? <Typography sx={{ color: path === "/islemler/harita" && isActive2 ? "rgba(77, 77, 77, 1)" : "white" }}>{t("harita")}</Typography> : <></>}

                                    </Box> */}

                                    <Box onClick={() => { path === "/tanimlar/takip" ? <></> : history.push("/islemler/takip") }}
                                        sx={{
                                            display: "flex", flexDirection: "row", padding: window.innerWidth > 675 ? "12px 16px" : "8px", margin: "8px 0", borderRadius: "12px",
                                            backgroundColor: path === "/islemler/takip" ? "white" : "",
                                            cursor: path === "/islemler/takip" ? "default" : "pointer",
                                            '&:hover': {
                                                backgroundColor: path === "/islemler/takip" ? "" : "rgba(71, 70, 70, 0.6)",
                                            }
                                        }}>

                                        <LocationOnIcon sx={{ width: "20px", marginRight: "8px", color: path === "/islemler/takip" && isActive2 ? "rgba(77, 77, 77, 1)" : "white" }} />
                                        {drawerOpen ? <Typography sx={{ color: path === "/islemler/takip" && isActive2 ? "rgba(77, 77, 77, 1)" : "white" }}>{t("takip")}</Typography> : <></>}

                                    </Box>


                                    <Box onClick={() => { path === "/tanimlar/ariza-emirleri" ? <></> : history.push("/islemler/ariza-emirleri") }}
                                        sx={{
                                            display: "flex", flexDirection: "row", padding: window.innerWidth > 675 ? "12px 16px" : "8px", margin: "8px 0", borderRadius: "12px",
                                            backgroundColor: path === "/islemler/ariza-emirleri" ? "white" : "",
                                            cursor: path === "/islemler/ariza-emirleri" ? "default" : "pointer",
                                            '&:hover': {
                                                backgroundColor: path === "/islemler/ariza-emirleri" ? "" : "rgba(71, 70, 70, 0.6)",
                                            }
                                        }}>

                                        <WarningIcon sx={{ width: "20px", marginRight: "8px", color: path === "/islemler/ariza-emirleri" && isActive2 ? "rgba(77, 77, 77, 1)" : "white" }} />
                                        {drawerOpen ? <Typography sx={{ color: path === "/islemler/ariza-emirleri" && isActive2 ? "rgba(77, 77, 77, 1)" : "white" }}>{t("bitmemişarızalar")}</Typography> : <></>}

                                    </Box>

                                    <Box onClick={() => { path === "/tanimlar/bakim-emirleri" ? <></> : history.push("/islemler/bakim-emirleri") }}
                                        sx={{
                                            display: "flex", flexDirection: "row", padding: window.innerWidth > 675 ? "12px 16px" : "8px", margin: drawerOpen ? "8px 0 16px 0" : 0, borderRadius: "12px",
                                            backgroundColor: path === "/islemler/bakim-emirleri" ? "white" : "",
                                            cursor: path === "/islemler/bakim-emirleri" ? "default" : "pointer",
                                            '&:hover': {
                                                backgroundColor: path === "/islemler/bakim-emirleri" ? "" : "rgba(71, 70, 70, 0.6)",
                                            }
                                        }}>

                                        <EngineeringIcon sx={{ width: "20px", marginRight: "8px", color: path === "/islemler/bakim-emirleri" && isActive2 ? "rgba(77, 77, 77, 1)" : "white" }} />
                                        {drawerOpen ? <Typography sx={{ color: path === "/islemler/bakim-emirleri" && isActive2 ? "rgba(77, 77, 77, 1)" : "white" }}>{t("bitmemişbakımlar")}</Typography> : <></>}

                                    </Box>

                                </AccordionDetails>
                            </Accordion>
                        </Button>
                        <Divider sx={{ margin: "4px 0", borderColor: "white" }} />

                        {/*RAPORLAR*/}
                        <Button className='raporlar-button'>
                            <Accordion expanded={expanded === 'panel3'} onChange={handleChange('panel3')} className='asd123' sx={{ boxShadow: "none", backgroundColor: isActive3 ? "rgba(77, 77, 77, 1)" : "white", }}>
                                <AccordionSummary
                                    onClick={() => { setIsActive(false); setIsActive2(false); setIsActive3(!isActive3); }}
                                    sx={{
                                        borderRadius: "12px",
                                        padding: "0 8px", '&:hover': {
                                            backgroundColor: 'rgba(77, 77, 77,0.05)',
                                        }, margin: "0px",
                                    }}
                                    expandIcon={drawerOpen ? <GridExpandMoreIcon sx={{ color: isActive3 ? "white" : "rgba(77, 77, 77, 1)" }} /> : <></>}
                                    aria-controls="panel1a-content"
                                    id="panel1a-header"
                                >
                                    <Box sx={{ display: "flex", alignItems: "center", flexDirection: "row", }}>
                                        <AssessmentOutlined sx={{ marginRight: drawerOpen ? "10px" : 0, marginLeft: drawerOpen ? 0 : window.innerWidth < 675 ? 0 : "8px", color: isActive3 ? "white" : "rgba(77, 77, 77, 1)" }} />
                                        {drawerOpen ? <Typography sx={{ color: isActive3 ? "white" : "rgba(77, 77, 77, 1)" }}>{t("raporlar")}</Typography> : <></>}
                                    </Box>
                                </AccordionSummary>

                                <AccordionDetails sx={{ padding: drawerOpen ? " 0 16px" : "2px" }}>

                                    <Box onClick={() => { path === "/tanimlar/bitmis-arizalar" ? <></> : history.push("/raporlar/bitmis-arizalar") }}
                                        sx={{
                                            display: "flex", flexDirection: "row", padding: window.innerWidth > 675 ? window.innerWidth > 675 ? "12px 16px" : "8px" : "8px", margin: "8px 0", borderRadius: "12px",
                                            backgroundColor: pathShort === "/raporlar/bitmis-arizalar" || pathShort === "/raporlar/bitmis-arizalar-detay" ? "white" : "",
                                            cursor: pathShort === "/raporlar/bitmis-arizalar" || pathShort === "/raporlar/bitmis-arizalar-detay" ? "default" : "pointer",
                                            '&:hover': {
                                                backgroundColor: pathShort === "/raporlar/bitmis-arizalar" || pathShort === "/raporlar/bitmis-arizalar-detay" ? "" : "rgba(71, 70, 70, 0.6)",
                                            }
                                        }}>

                                        <CheckCircleIcon sx={{ width: "20px", marginRight: "8px", color: pathShort === "/raporlar/bitmis-arizalar" || pathShort === "/raporlar/bitmis-arizalar-detay" && isActive3 ? "rgba(77, 77, 77, 1)" : "white" }} />
                                        {drawerOpen ? <Typography sx={{ color: pathShort === "/raporlar/bitmis-arizalar" || pathShort === "/raporlar/bitmis-arizalar-detay" && isActive3 ? "rgba(77, 77, 77, 1)" : "white" }}>{t("bitmişarızalar")}</Typography> : <></>}
                                    </Box>

                                    <Box onClick={() => { path === "/tanimlar/bitmis-bakimlar" ? <></> : history.push("/raporlar/bitmis-bakimlar") }}
                                        sx={{
                                            display: "flex", flexDirection: "row", padding: window.innerWidth > 675 ? "12px 16px" : "8px", margin: "8px 0", borderRadius: "12px",
                                            backgroundColor: pathShort === "/raporlar/bitmis-bakimlar" || pathShort === "/raporlar/bitmis-bakimlar-detay" ? "white" : "",
                                            cursor: pathShort === "/raporlar/bitmis-bakimlar" || pathShort === "/raporlar/bitmis-bakimlar-detay" ? "default" : "pointer",
                                            '&:hover': {
                                                backgroundColor: pathShort === "/raporlar/bitmis-bakimlar" || pathShort === "/raporlar/bitmis-bakimlar-detay" ? "" : "rgba(71, 70, 70, 0.6)",
                                            }
                                        }}>

                                        <CheckCircleIcon sx={{ width: "20px", marginRight: "8px", color: pathShort === "/raporlar/bitmis-bakimlar" || pathShort === "/raporlar/bitmis-bakimlar-detay" && isActive3 ? "rgba(77, 77, 77, 1)" : "white" }} />
                                        {drawerOpen ? <Typography sx={{ color: pathShort === "/raporlar/bitmis-bakimlar" || pathShort === "/raporlar/bitmis-bakimlar-detay" && isActive3 ? "rgba(77, 77, 77, 1)" : "white" }}>{t("bitmişbakımlar")}</Typography> : <></>}
                                    </Box>

                                    {/*   <Box onClick={() => { path === "/tanimlar/aylik-bakim-raporu" ? <></> : history.push("/raporlar/aylik-bakim-raporu") }}
                                        sx={{
                                            display: "flex", flexDirection: "row", padding: window.innerWidth > 675 ? "12px 16px" : "8px", margin: "8px 0", borderRadius: "12px",
                                            backgroundColor: path === "/raporlar/aylik-bakim-raporu" ? "white" : "",
                                            cursor: path === "/raporlar/aylik-bakim-raporu" ? "default" : "pointer",
                                            '&:hover': {
                                                backgroundColor: path === "/raporlar/aylik-bakim-raporu" ? "" : "rgba(71, 70, 70, 0.6)",
                                            }
                                        }}>

                                        <AssessmentIcon sx={{ width: "20px", marginRight: "8px", color: path === "/raporlar/aylik-bakim-raporu" && isActive3 ? "rgba(77, 77, 77, 1)" : "white" }} />
                                        {drawerOpen ? <Typography sx={{ color: path === "/raporlar/aylik-bakim-raporu" && isActive3 ? "rgba(77, 77, 77, 1)" : "white", textOverflow: "ellipsis", overflow: "hidden" }}>{t("aylıkbakımraporu")}</Typography> : <></>}
                                    </Box>

                                    <Box onClick={() => { path === "/tanimlar/aylik-malzeme-raporu" ? <></> : history.push("/raporlar/aylik-malzeme-raporu") }}
                                        sx={{
                                            display: "flex", flexDirection: "row", padding: window.innerWidth > 675 ? "12px 16px" : "8px", margin: drawerOpen ? "8px 0 16px 0" : 0, borderRadius: "12px",
                                            backgroundColor: path === "/raporlar/aylik-malzeme-raporu" ? "white" : "",
                                            cursor: path === "/raporlar/aylik-malzeme-raporu" ? "default" : "pointer",
                                            '&:hover': {
                                                backgroundColor: path === "/raporlar/aylik-malzeme-raporu" ? "" : "rgba(71, 70, 70, 0.6)",
                                            }
                                        }}>

                                        <AssessmentIcon sx={{ width: "20px", marginRight: "8px", color: path === "/raporlar/aylik-malzeme-raporu" && isActive3 ? "rgba(77, 77, 77, 1)" : "white" }} />
                                        {drawerOpen ? <Typography sx={{ color: path === "/raporlar/aylik-malzeme-raporu" && isActive3 ? "rgba(77, 77, 77, 1)" : "white" }}>{t("aylıkmalzemeraporu")}</Typography> : <></>}
                                    </Box> */}

                                </AccordionDetails>
                            </Accordion>
                        </Button>
                    </Box >

                    <Box>

                        <Box sx={{ display: "flex", flexDirection: "column", width: "100%", }}>
                            <Link className='layout-button-link' to='/kullanici/ayarlar'>
                                <Box sx={{
                                    '&:hover': {
                                        backgroundColor: path === "/kullanici/ayarlar" ? 'rgba(77, 77, 77, 1)' : 'rgba(77, 77, 77,0.15)',
                                    }, display: "flex", justifyContent: drawerOpen ? "flex-start" : "center", alignItems: "center", width: "100%", flexDirection: "row", padding: "12px 8px", borderRadius: "12px", margin: "0 0 8px 0", backgroundColor: path === "/kullanici/ayarlar" ? "rgba(77, 77, 77, 1)" : "white",
                                }}>
                                    <Settings sx={{ marginRight: drawerOpen ? "10px" : "0px", color: path === "/kullanici/ayarlar" ? "white" : "primary" }} />
                                    {drawerOpen ? <Typography
                                        sx={{
                                            textTransform: "capitalize", color: path === "/kullanici/ayarlar" ? "white" : "rgba(77, 77, 77, 1)"
                                        }}>
                                        {t("ayarlar")}
                                    </Typography> : <></>}
                                </Box>
                            </Link>
                        </Box>

                        <Box sx={{ display: "flex", flexDirection: "column", width: "100%", }}>
                            <Link className='layout-button-link' to='/support'>
                                <Box sx={{
                                    '&:hover': {
                                        backgroundColor: path === "/support" ? 'rgba(77, 77, 77, 1)' : 'rgba(77, 77, 77,0.15)',
                                    }, display: "flex", justifyContent: drawerOpen ? "flex-start" : "center", alignItems: "center", width: "100%", flexDirection: "row", padding: "12px 8px", borderRadius: "12px", margin: "0 0 8px 0", backgroundColor: path === "/support" ? "rgba(77, 77, 77, 1)" : "white",
                                }}>
                                    <img src={path === "/support" ? '/assets/questionMarkLight.svg' : "/assets/questionMarkDark.svg"} style={{ width: "20px", marginRight: drawerOpen ? "10px" : "0px", color: path === "/support" ? "white" : "rgba(77, 77, 77, 1)" }} />
                                    {drawerOpen ? <Typography
                                        sx={{
                                            textTransform: "capitalize", color: path === "/support" ? "white" : "rgba(77, 77, 77, 1)"
                                        }}>
                                        {t("destek")}
                                    </Typography> : <></>}
                                </Box>
                            </Link>
                        </Box>

                        <Box sx={{ display: "flex", flexDirection: "column", width: "100%", marginBottom: "1rem" }}>
                            <div onClick={() => {
                                logoutRequest()
                                history.push("/login")
                            }} style={{ display: "flex", justifyContent: drawerOpen ? "flex-start" : "center", flexDirection: "row", alignItems: "center", padding: "12px 16px", width: "100%", cursor: "pointer", border: "1px solid rgba(77, 77, 77, 1)", borderRadius: "10px" }}>
                                <LogoutIcon sx={{ marginRight: drawerOpen ? "10px" : "0px", }} />
                                {drawerOpen ? <Typography
                                    sx={{
                                        color: theme.secondary,
                                        alignSelf: "center",
                                        justifySelf: "center",
                                        fontSize: "14px",
                                        width: "100%"
                                    }}>
                                    {t("çıkışyap")}
                                </Typography> : <></>}
                            </div>
                        </Box>
                    </Box>

                </List>

            </Drawer>

            <Box component="main" sx={{ flexGrow: 1, p: 0, backgroundColor: theme.palette.background.default }}>
                <DrawerHeader />

                {children}
            </Box>

            {/* CHNAGE LANGUAGE DIALOG */}
            <CustomDialog
                maxWidth="xs"
                title={t("dildeğiştir")}
                open={isOpen}
                close={handleLangClose}
                buttons={[
                    <Button key="cancel" onClick={() => handleLangClose()}>{t("iptalet")}</Button>,

                ]}
            >
                <Grid container spacing={2}>

                    <Grid item xs={12} md={12}>
                        <div style={{ display: "flex", justifyContent: "center" }}>
                            <div style={{ display: "flex", width: "100%", maxWidth: "200px" }}>
                                <FormControl fullWidth >
                                    <InputLabel shrink={true} id="demo-simple-select-label">{t("Language")}</InputLabel>
                                    <Select
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        /*   value={currentLang ? currentLang : ""} */
                                        label={"language"}
                                    /*  onChange={(e) => handleChangeLang(e.target.value)} */
                                    >
                                        <MenuItem value={"tr"} >
                                            <div style={{ display: "flex", flexDirection: "row", alignItems: "center", width: "100%", gap: "8px" }}>
                                                {/* <img style={{ width: "24px ", height: "18px", borderRadius: "3px" }} src='../assets/tr.png' /> */}
                                                <div>
                                                    Türkçe
                                                </div>
                                            </div>
                                        </MenuItem>
                                        <MenuItem value={"en"} img={"./assets/en.jpg"}>
                                            <div style={{ display: "flex", flexDirection: "row", alignItems: "center", width: "100%", gap: "8px" }}>
                                                {/* <img style={{ width: "24px ", height: "18px", borderRadius: "3px" }} src='../assets/en.jpg' /> */}
                                                <div>
                                                    English
                                                </div>
                                            </div>
                                        </MenuItem>
                                    </Select>
                                </FormControl>
                            </div>
                        </div>
                    </Grid>

                </Grid>

            </CustomDialog>
        </Box >
    );
}


export default CustomLayout




