import {
  LoadingButton
} from "@mui/lab";
import {
  Autocomplete,
  Box,
  Button,
  Divider,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  TextField,
  Typography,
  useTheme
} from "@mui/material";
import Switch from "@mui/material/Switch";
import { useSnackbar } from "notistack";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  getCurrentCompanyRequest,
  getCurrentUserRequest,
} from "../../api/controllers/home-controller";
import {
  authorizeBillRequest,
  getBillInfo,
  getCompanyRequest,
  updateAccountingStatusRequest,
  updateEditPermissionStatusRequest,
  updateMasterAccountingStatusRequest,
  updateMasterEditPermissionStatusRequest,
  updateMasterIssueNotificationStatusRequest,
  updateMasterMaintenanceNotificationStatusRequest,
  updateMonthlyMaintenanceBalance,
  updateSmsSendingCreatedMaintenance,
  updateSmsSendingFinishedIssue,
  updateSmsSendingFinishedIssueBalance,
  updateSmsSendingFinishedIssueItems,
  updateSmsSendingFinishedIssueNote,
  updateSmsSendingFinishedMaintenance,
  updateSmsSendingFinishedMaintenanceBalance,
  updateSmsSendingFinishedMaintenanceNote,
  updateSmsSendingStartedIssue,
  updateSmsSendingStartedMaintenance,
} from "../../api/controllers/setting-controller";
import CustomDialog from "../../components/CustomDialog";
import i18n from "../../i18n";
import { updatePermissionForElevatorDisplayRequest, updateQrPermissions } from "../../api/controllers/user-controller";

const Page = () => {
  const { t } = useTranslation("common");
  document.title = "Aselmind | " + t("ayarlar");
  const theme = useTheme();
  const { enqueueSnackbar } = useSnackbar();

  const [userData, setUserData] = useState({});
  const [companyData, setCompanyData] = useState({});
  const [settings, setSettings] = useState({});

  const [currentLang, setCurrentLang] = useState(
    localStorage.getItem("currentLang")
      ? localStorage.getItem("currentLang")
      : localStorage.setItem("currentLang", "tr")
  );
  const [currentCurrency, setCurrentCurrency] = useState(
    localStorage.getItem("currentCurrency")
      ? localStorage.getItem("currentCurrency")
      : localStorage.setItem("currentCurrency", "try")
  );
  const [currencySign, setCurrencySign] = useState(
    localStorage.getItem("currencySign")
      ? localStorage.getItem("currencySign")
      : localStorage.setItem("currencySign", "₺")
  );

  const handleChangeLang = (e) => {
    setCurrentLang(e);
    localStorage.setItem("currentLang", e);
    i18n.changeLanguage(e);
  };

  const handleChangeCurrency = (e) => {
    e === "try" ? (
      localStorage.setItem("currencySign", "₺")
    ) : e === "usd" ? (
      localStorage.setItem("currencySign", "$")
    ) : e === "eur" ? (
      localStorage.setItem("currencySign", "€")
    ) : e === "qar" ? (
      localStorage.setItem("currencySign", "QR")
    ) : (
      <></>
    );

    e === "try" ? (
      localStorage.setItem("currentCurrency", "try")
    ) : e === "usd" ? (
      localStorage.setItem("currentCurrency", "usd")
    ) : e === "eur" ? (
      localStorage.setItem("currentCurrency", "eur")
    ) : e === "qar" ? (
      localStorage.setItem("currentCurrency", "qar")
    ) : (
      <></>
    );

    let sign = localStorage.getItem("currencySign");
    setCurrencySign(sign);

    let currency = localStorage.getItem("currentCurrency");
    setCurrentCurrency(currency);
  };

  // GET USER DATA
  const fetchUserData = async () => {
    try {
      let res = await getCurrentUserRequest();
      if (res) {
        setUserData(res?.data);
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  // GET COMPANY LOGO
  const fetcCompanyLogo = async () => {
    try {
      let res = await getCurrentCompanyRequest();
      if (res) {
        setCompanyData(res?.data);
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  const [createdMaintenanceSmsSending, setCreatedMaintenanceSmsSending] =
    useState();
  const [editPermission, setEditPermission] = useState();
  const [finishedIssueSmsSending, setFinishedIssueSmsSending] = useState();
  const [finishedIssueItemsSmsSending, setFinishedIssueItemsSmsSending] =
    useState();
  const [
    finishedIssueWithBalanceSmsSending,
    setFinishedIssueWithBalanceSmsSending,
  ] = useState();
  const [
    finishedIssueWithNotesSmsSending,
    setFinishedIssueWithNotesSmsSending,
  ] = useState();
  const [finishedMaintenanceSmsSending, setFinishedMaintenanceSmsSending] =
    useState();
  const [
    finishedMaintenanceWithBalanceSmsSending,
    setFinishedMaintenanceWithBalanceSmsSending,
  ] = useState();
  const [
    finishedMaintenanceWithNotesSmsSending,
    setFinishedMaintenanceWithNotesSmsSending,
  ] = useState();
  const [isAccounting, setIsAccounting] = useState();
  const [master_editPermission, setMaster_editPermission] = useState();
  const [master_isAccounting, setMaster_isAccounting] = useState();
  const [master_issueNotification, setMaster_issueNotification] = useState();
  const [master_maintenanceNotification, setMaster_maintenanceNotification] =
    useState();
  const [
    monthlyMaintenanceBalanceAutomatically,
    setMonthlyMaintenanceBalanceAutomatically,
  ] = useState();
  const [startedIssueSmsSending, setStartedIssueSmsSending] = useState();
  const [startedMaintenanceSmsSending, setStartedMaintenanceSmsSending] =
    useState();
  const [qrPermissions, setQrPermissions] = useState(false)
  const [permissionForElevatorDisplay, setPermissionForElevatorDisplay] = useState(false)

  // GET SETTINGS
  const fetchSettings = async () => {
    try {
      let res = await getCompanyRequest();
      if (res) {
        setSettings(res?.data);
        setFinishedIssueItemsSmsSending(res.data.finishedIssueItemsSmsSending);
        setFinishedIssueWithBalanceSmsSending(
          res.data.finishedIssueWithBalanceSmsSending
        );
        setIsAccounting(res.data.isAccounting);
        setEditPermission(res.data.editPermission);
        setMaster_isAccounting(res.data.master_isAccounting);
        setMaster_editPermission(res.data.master_editPermission);
        setMaster_issueNotification(res.data.master_issueNotification);
        setMaster_maintenanceNotification(
          res.data.master_maintenanceNotification
        );
        setCreatedMaintenanceSmsSending(res.data.createdMaintenanceSmsSending);
        setStartedMaintenanceSmsSending(res.data.startedMaintenanceSmsSending);
        setFinishedMaintenanceSmsSending(
          res.data.finishedMaintenanceSmsSending
        );
        setFinishedMaintenanceWithNotesSmsSending(
          res.data.finishedMaintenanceWithNotesSmsSending
        );
        setStartedIssueSmsSending(res.data.startedIssueSmsSending);
        setFinishedIssueSmsSending(res.data.finishedIssueSmsSending);
        setFinishedIssueWithNotesSmsSending(
          res.data.finishedIssueWithNotesSmsSending
        );
        setMonthlyMaintenanceBalanceAutomatically(
          res.data.monthlyMaintenanceBalanceAutomatically
        );
        setFinishedMaintenanceWithBalanceSmsSending(
          res.data.finishedMaintenanceWithBalanceSmsSending
        );
        setQrPermissions(
          res.data?.permissionForQR
        );
        setPermissionForElevatorDisplay(
          res.data?.permissionForElevatorDisplay
        );
        //yeni özellik setCodeForm({ ...codeForm, issueCode: res.data.issueCode, maintenanceCode: res.data.maintenanceCode })
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  const changeStatus = async ({ status, witchSetting }) => {
    try {
      let res =
        witchSetting === "Accounting" ? (
          await updateAccountingStatusRequest(status)
        ) : witchSetting === "EditPermission" ? (
          await updateEditPermissionStatusRequest(status)
        ) : witchSetting === "MasterAccounting" ? (
          await updateMasterAccountingStatusRequest(status)
        ) : witchSetting === "MasterEditPermission" ? (
          await updateMasterEditPermissionStatusRequest(status)
        ) : witchSetting === "MasterIssueNotification" ? (
          await updateMasterIssueNotificationStatusRequest(status)
        ) : witchSetting === "MasterMaintenanceNotification" ? (
          await updateMasterMaintenanceNotificationStatusRequest(status)
        ) : witchSetting === "SmsSendingCreatedMaintenance" ? (
          await updateSmsSendingCreatedMaintenance(status)
        ) : witchSetting === "SmsSendingStartedMaintenance" ? (
          await updateSmsSendingStartedMaintenance(status)
        ) : witchSetting === "SmsSendingFinishedMaintenance" ? (
          await updateSmsSendingFinishedMaintenance(status)
        ) : witchSetting === "SmsSendingFinishedMaintenanceNote" ? (
          await updateSmsSendingFinishedMaintenanceNote(status)
        ) : witchSetting === "SmsSendingStartedIssue" ? (
          await updateSmsSendingStartedIssue(status)
        ) : witchSetting === "SmsSendingFinishedIssue" ? (
          await updateSmsSendingFinishedIssue(status)
        ) : witchSetting === "SmsSendingFinishedIssueNote" ? (
          await updateSmsSendingFinishedIssueNote(status)
        ) : witchSetting === "MonthlyMaintenanceBalance" ? (
          await updateMonthlyMaintenanceBalance(status)
        ) : witchSetting === "SmsSendingFinishedMaintenanceBalance" ? (
          await updateSmsSendingFinishedMaintenanceBalance(status)
        ) : witchSetting === "SmsSendingFinishedIssueItems" ? (
          await updateSmsSendingFinishedIssueItems(status)
        ) : witchSetting === "SmsSendingFinishedIssueBalance" ? (
          await updateSmsSendingFinishedIssueBalance(status)
        ) : witchSetting === "QrPermissions" ? (
          await updateQrPermissions(status)
        ) : witchSetting === "permissionForElevatorDisplay" ? (
          await updatePermissionForElevatorDisplayRequest(status)
        ) : (
          <></>
        );
      if (res) {
        fetchSettings();
      }
    } catch (error) {
      console.log("error", error);
    }
  };



  const defaultCodeForm = {
    issueCode: "",
    maintenanceCode: "",
  };

  const [codeForm, setCodeForm] = useState(defaultCodeForm);

  const [selectedAccountingProgramList, setSelectedAccountingProgramList] =
    useState(null);
  const [accountingProgramList, setAccountingProgramList] = useState([
    {
      key: t("PARAŞÜT"),
      value: "PARAŞÜT",
    },
  ]);
  const defaultBillForm = {
    client_id: "",
    client_secret: "",
    password: "",
    username: "",
    issueCode: "",
    maintenanceCode: "",
  };

  const [billForm, setBillForm] = useState(defaultBillForm);
  const [accountingDialog, setAccountingDialog] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false);

  const closeAccountingDialog = () => {
    setAccountingDialog(false);
    setSelectedAccountingProgramList(null);
    setBillForm(defaultBillForm);
  };

  const handleSaveBillAuth = async () => {
    try {
      /*   let issue = await updateIssueCodeRequest(codeForm?.issueCode)
        let maintenance = await updateMaintenanceCodeRequest(codeForm?.maintenanceCode)
        if (issue) {
          enqueueSnackbar(t('arızakodukaydedildi'), { variant: "success" });
        }
        if (maintenance) {
          enqueueSnackbar(t('bakımkodukaydedildi'), { variant: "success" });
        } */
      let res = await authorizeBillRequest(billForm);
      if (res) {
        enqueueSnackbar(t("muhasebebilgilerikaydedildi"), {
          variant: "success",
        });
        closeAccountingDialog();
      }
    } catch (error) {
      error?.response?.data?.map((v) =>
        enqueueSnackbar(v?.message, { variant: "error" })
      );
    }
  };

  const openAccountingDialog = () => {
    setAccountingDialog(true);
  };

  const fetchBillInfo = async () => {
    try {
      let res = await getBillInfo();
      if (res) {
        setBillForm(res.data);
      }
    } catch (error) {
      enqueueSnackbar(t("birşeylertersgitti"), { variant: "error" });
    }
  };

  useEffect(() => {
    fetchUserData();
    fetcCompanyLogo();
    fetchSettings();
  }, []);

  const handleChangeSelectedAccountingProgram = () => {
    if (selectedAccountingProgramList?.value === "PARAŞÜT") {
      fetchBillInfo();
    }
  };

  useEffect(() => {
    handleChangeSelectedAccountingProgram();
  }, [selectedAccountingProgramList]);

  return (
    <Box p={3}>
      <Paper
        elevation={0}
        sx={{
          display: "flex",
          padding: theme.spacing(2),
          alignItems: "center",
          justifyContent: "space-between",
          flexDirection: { xs: "column", sm: "column", md: "row", lg: "row" },
        }}
      >
        <Typography
          sx={{ fontSize: window.innerWidth < 675 ? "1.75rem" : "2.125rem" }}
          variant="h4"
        >
          {t("ayarlar")}
        </Typography>
      </Paper>
      <Paper
        elevation={0}
        sx={{
          padding: theme.spacing(2),
          margin: theme.spacing(2, 0),
        }}
      >
        <div
          style={{
            display: "flex",
            width: "100%",
            justifyContent: "flex-end",
            flexDirection: "row",
            alignItems: "center",
            paddingRight: "40px",
          }}
        >
          <Button
            variant="contained"
            color="secondary"
            size="large"
            style={{ marginRight: "20px" }}
            onClick={() => openAccountingDialog()}
          >
            {t("muhasebe")}
          </Button>
          <div
            style={{
              display: "flex",
              width: "100%",
              maxWidth: "100px",
              marginRight: "20px",
            }}
          >
            <FormControl fullWidth>
              <InputLabel shrink={true} id="demo-simple-select-label">
                {t("Language")}
              </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={currentLang ? currentLang : ""}
                label={"language"}
                onChange={(e) => handleChangeLang(e.target.value)}
              >
                <MenuItem value={"tr"}>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      width: "100%",
                      gap: "8px",
                    }}
                  >
                    {/* <img style={{ width: "24px ", height: "18px", borderRadius: "3px" }} src='../assets/tr.png' /> */}
                    <div>Türkçe</div>
                  </div>
                </MenuItem>
                <MenuItem value={"en"}>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      width: "100%",
                      gap: "8px",
                    }}
                  >
                    {/* <img style={{ width: "24px ", height: "18px", borderRadius: "3px" }} src='../assets/en.jpg' /> */}
                    <div>English</div>
                  </div>
                </MenuItem>
              </Select>
            </FormControl>
          </div>
          <div style={{ display: "flex", width: "100%", maxWidth: "75px" }}>
            <FormControl fullWidth>
              <InputLabel shrink={true} id="demo-simple-select-label">
                {t("Currency")}
              </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={currentCurrency}
                label={"currency"}
                onChange={(e) => handleChangeCurrency(e.target.value)}
              >
                <MenuItem value={"try"}>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      width: "100%",
                      gap: "8px",
                    }}
                  >
                    {/* <img style={{ width: "24px ", height: "18px", borderRadius: "3px" }} src='../assets/tr.png' /> */}
                    <div>TRY</div>
                  </div>
                </MenuItem>
                <MenuItem value={"usd"}>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      width: "100%",
                      gap: "8px",
                    }}
                  >
                    <div>USD</div>
                  </div>
                </MenuItem>
                <MenuItem value={"eur"}>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      width: "100%",
                      gap: "8px",
                    }}
                  >
                    <div>EUR</div>
                  </div>
                </MenuItem>
                <MenuItem value={"qar"}>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      width: "100%",
                      gap: "8px",
                    }}
                  >
                    <div>QAR</div>
                  </div>
                </MenuItem>
              </Select>
            </FormControl>
          </div>
          {/* <div style={{ display: "flex", width: "100%", maxWidth: "75px" }}>

          </div> */}
        </div>

        <Grid container spacing={2}>
          <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                backgroundColor: "white",
                padding: "1rem",
                maxWidth: "500px",
              }}
            >
              <Typography
                sx={{
                  paddingLeft: "10px",
                  color: theme.palette.secondary.main,
                }}
              >
                {t("yetkiayarlarım")}
              </Typography>

              <Divider sx={{ width: "100%", margin: "12px 0" }} />

              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  padding: "6px 0",
                }}
              >
                <Switch
                  checked={isAccounting}
                  onClick={() =>
                    changeStatus({
                      status: !isAccounting,
                      witchSetting: "Accounting",
                    })
                  }
                  color="secondary"
                />
                <Typography sx={{ wordBreak: "break-word", fontSize: "14px" }}>
                  {t("çalışanlaratahsilatözelliğiver")}
                </Typography>
              </Box>

              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  padding: "6px 0",
                }}
              >
                <Switch
                  checked={editPermission}
                  onClick={() =>
                    changeStatus({
                      status: !editPermission,
                      witchSetting: "EditPermission",
                    })
                  }
                  color="secondary"
                />
                <Typography sx={{ wordBreak: "break-word", fontSize: "14px" }}>
                  {t("çalışanlaradüzenlemeözelliğiver")}
                </Typography>
              </Box>

              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  padding: "6px 0",
                }}
              >
                <Switch
                  disabled
                  checked={true /* master_isAccounting */}
                  onClick={() =>
                    changeStatus({
                      status: !master_isAccounting,
                      witchSetting: "MasterAccounting",
                    })
                  }
                  color="secondary"
                />
                <Typography sx={{ wordBreak: "break-word", fontSize: "14px" }}>
                  {t("yöneticileretahsilatözelliğiver")}
                </Typography>
              </Box>

              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  padding: "6px 0",
                }}
              >
                <Switch
                  disabled
                  checked={true /* master_editPermission */}
                  onClick={() =>
                    changeStatus({
                      status: !master_editPermission,
                      witchSetting: "MasterEditPermission",
                    })
                  }
                  color="secondary"
                />
                <Typography sx={{ wordBreak: "break-word", fontSize: "14px" }}>
                  {t("yöneticileredüzenlemeözelliğiver")}
                </Typography>
              </Box>
            </Box>

            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                backgroundColor: "white",
                padding: "1rem",
                maxWidth: "500px",
              }}
            >
              <Typography
                sx={{
                  paddingLeft: "10px",
                  color: theme.palette.secondary.main,
                }}
              >
                {t("bildirimayarlarım")}
              </Typography>

              <Divider sx={{ width: "100%", margin: "12px 0" }} />

              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  padding: "6px 0",
                }}
              >
                <Switch
                  checked={master_issueNotification}
                  onClick={() =>
                    changeStatus({
                      status: !master_issueNotification,
                      witchSetting: "MasterIssueNotification",
                    })
                  }
                  color="secondary"
                />
                <Typography sx={{ wordBreak: "break-word", fontSize: "14px" }}>
                  {t("arızabittimesajıyöneticibildirimlerinedüşsünmü")}
                </Typography>
              </Box>

              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  padding: "6px 0",
                }}
              >
                <Switch
                  checked={master_maintenanceNotification}
                  onClick={() =>
                    changeStatus({
                      status: !master_maintenanceNotification,
                      witchSetting: "MasterMaintenanceNotification",
                    })
                  }
                  color="secondary"
                />
                <Typography sx={{ wordBreak: "break-word", fontSize: "14px" }}>
                  {t("bakımbittimesajıyöneticibildirimlerinedüşsünmü")}
                </Typography>
              </Box>
            </Box>

            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                backgroundColor: "white",
                padding: "1rem",
                maxWidth: "500px",
              }}
            >
              <Typography
                sx={{
                  paddingLeft: "10px",
                  color: theme.palette.secondary.main,
                }}
              >
                {t("diğerayarlarım")}
              </Typography>

              <Divider sx={{ width: "100%", margin: "12px 0" }} />

              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  padding: "6px 0",
                }}
              >
                <Switch
                  checked={monthlyMaintenanceBalanceAutomatically}
                  onClick={() =>
                    changeStatus({
                      status: !monthlyMaintenanceBalanceAutomatically,
                      witchSetting: "MonthlyMaintenanceBalance",
                    })
                  }
                  color="secondary"
                />
                <Typography sx={{ wordBreak: "break-word", fontSize: "14px" }}>
                  {t("bakımücretiniherayın1indeotomatikolarakdüş")}
                </Typography>
              </Box>

              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  padding: "6px 0",
                }}
              >
                <Switch
                  checked={qrPermissions}
                  onClick={() =>
                    changeStatus({
                      status: !qrPermissions,
                      witchSetting: "QrPermissions",
                    })
                  }
                  color="secondary"
                />
                <Typography sx={{ wordBreak: "break-word", fontSize: "14px" }}>
                  {t("qralanınıelilegirebilsin")}
                </Typography>
              </Box>

              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  padding: "6px 0",
                }}
              >
                <Switch
                  checked={permissionForElevatorDisplay}
                  onClick={() =>
                    changeStatus({
                      status: !permissionForElevatorDisplay,
                      witchSetting: "permissionForElevatorDisplay",
                    })
                  }
                  color="secondary"
                />
                <Typography sx={{ wordBreak: "break-word", fontSize: "14px" }}>
                  {t("calisanlarbinalarıgörebilir")}
                </Typography>
              </Box>

              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  padding: "6px 0",
                }}
              >
                <Switch
                  disabled
                  /*  checked={state} */
                  /* onClick={() => changeStatusAccounting()} */
                  color="secondary"
                />
                <Typography sx={{ wordBreak: "break-word", fontSize: "14px" }}>
                  {t("yedeklemeal")}
                </Typography>
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                backgroundColor: "white",
                padding: "1rem",
                maxWidth: "500px",
              }}
            >
              <Typography
                sx={{
                  paddingLeft: "10px",
                  color: theme.palette.secondary.main,
                }}
              >
                {t("smsayarlarım")}
              </Typography>

              <Divider sx={{ width: "100%", margin: "12px 0" }} />

              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  padding: "6px 0",
                }}
              >
                <Switch
                  checked={finishedMaintenanceSmsSending}
                  onClick={() =>
                    changeStatus({
                      status: !finishedMaintenanceSmsSending,
                      witchSetting: "SmsSendingFinishedMaintenance",
                    })
                  }
                  color="secondary"
                />
                <Typography sx={{ wordBreak: "break-word", fontSize: "14px" }}>
                  {t("bakımbittimesajıgönder")}
                </Typography>
              </Box>

              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  padding: "6px 0",
                }}
              >
                <Switch
                  checked={finishedMaintenanceWithNotesSmsSending}
                  onClick={() =>
                    changeStatus({
                      status: !finishedMaintenanceWithNotesSmsSending,
                      witchSetting: "SmsSendingFinishedMaintenanceNote",
                    })
                  }
                  color="secondary"
                />
                <Typography sx={{ wordBreak: "break-word", fontSize: "14px" }}>
                  {t("bakımbittimesajındanotyazılsın")}
                </Typography>
              </Box>

              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  padding: "6px 0",
                }}
              >
                <Switch
                  checked={startedIssueSmsSending}
                  onClick={() =>
                    changeStatus({
                      status: !startedIssueSmsSending,
                      witchSetting: "SmsSendingStartedIssue",
                    })
                  }
                  color="secondary"
                />
                <Typography sx={{ wordBreak: "break-word", fontSize: "14px" }}>
                  {t("arızayabaşlandımesajıgönder")}
                </Typography>
              </Box>

              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  padding: "6px 0",
                }}
              >
                <Switch
                  checked={finishedIssueSmsSending}
                  onClick={() =>
                    changeStatus({
                      status: !finishedIssueSmsSending,
                      witchSetting: "SmsSendingFinishedIssue",
                    })
                  }
                  color="secondary"
                />
                <Typography sx={{ wordBreak: "break-word", fontSize: "14px" }}>
                  {t("arızabittimesajıgönder")}
                </Typography>
              </Box>

              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  padding: "6px 0",
                }}
              >
                <Switch
                  checked={finishedIssueWithNotesSmsSending}
                  onClick={() =>
                    changeStatus({
                      status: !finishedIssueWithNotesSmsSending,
                      witchSetting: "SmsSendingFinishedIssueNote",
                    })
                  }
                  color="secondary"
                />
                <Typography sx={{ wordBreak: "break-word", fontSize: "14px" }}>
                  {t("arızabittimesajındanotyazılsın")}
                </Typography>
              </Box>

              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  padding: "6px 0",
                }}
              >
                <Switch
                  checked={finishedMaintenanceWithBalanceSmsSending}
                  onClick={() =>
                    changeStatus({
                      status: !finishedMaintenanceWithBalanceSmsSending,
                      witchSetting: "SmsSendingFinishedMaintenanceBalance",
                    })
                  }
                  color="secondary"
                />
                <Typography sx={{ wordBreak: "break-word", fontSize: "14px" }}>
                  {t("bakımbittismsiçindebakiyegönder")}
                </Typography>
              </Box>

              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  padding: "6px 0",
                }}
              >
                <Switch
                  checked={createdMaintenanceSmsSending}
                  onClick={() =>
                    changeStatus({
                      status: !createdMaintenanceSmsSending,
                      witchSetting: "SmsSendingCreatedMaintenance",
                    })
                  }
                  color="secondary"
                />
                <Typography sx={{ wordBreak: "break-word", fontSize: "14px" }}>
                  {t("güniçindebakımyapılacakmesajıgönder")}
                </Typography>
              </Box>

              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  padding: "6px 0",
                }}
              >
                <Switch
                  checked={startedMaintenanceSmsSending}
                  onClick={() =>
                    changeStatus({
                      status: !startedMaintenanceSmsSending,
                      witchSetting: "SmsSendingStartedMaintenance",
                    })
                  }
                  color="secondary"
                />
                <Typography sx={{ wordBreak: "break-word", fontSize: "14px" }}>
                  {t("bakımbaşladımesajıgönder")}
                </Typography>
              </Box>

              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  padding: "6px 0",
                }}
              >
                <Switch
                  checked={finishedIssueItemsSmsSending}
                  onClick={() =>
                    changeStatus({
                      status: !finishedIssueItemsSmsSending,
                      witchSetting: "SmsSendingFinishedIssueItems",
                    })
                  }
                  color="secondary"
                />
                <Typography sx={{ wordBreak: "break-word", fontSize: "14px" }}>
                  {t("arızabitimindeparçadetaylarınımesajgönder")}
                </Typography>
              </Box>

              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  padding: "6px 0",
                }}
              >
                <Switch
                  checked={finishedIssueWithBalanceSmsSending}
                  onClick={() =>
                    changeStatus({
                      status: !finishedIssueWithBalanceSmsSending,
                      witchSetting: "SmsSendingFinishedIssueBalance",
                    })
                  }
                  color="secondary"
                />
                <Typography sx={{ wordBreak: "break-word", fontSize: "14px" }}>
                  {t("arızabitimindebakiyemesajıgönder")}
                </Typography>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Paper>

      {/* ACCOUNTING DIALOG */}
      <CustomDialog
        maxWidth="xs"
        title={t("muhasebe")}
        open={accountingDialog}
        close={() => closeAccountingDialog()}
        buttons={[
          <Button key="cancel" onClick={() => closeAccountingDialog()}>
            {t("iptalet")}
          </Button>,
          <LoadingButton
            loading={isDisabled}
            key="save"
            onClick={(i) => handleSaveBillAuth(i)}
            disableElevation
            variant="contained"
            color="secondary"
          >
            {t("kaydet")}
          </LoadingButton>,
        ]}
      >
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Autocomplete
              options={accountingProgramList}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label={t("muhasebeprogramı")}
                  variant="standard"
                  fullWidth
                />
              )}
              getOptionLabel={(option) => option.key}
              filterOptions={(options, { inputValue }) =>
                options.filter((option) =>
                  option.key
                    .toLocaleUpperCase()
                    .includes(inputValue.toLocaleUpperCase())
                )
              }
              value={selectedAccountingProgramList}
              onChange={(event, newValue) => {
                setSelectedAccountingProgramList(newValue);
                /*  elevator.tagElevatorColor = newValue.value
                setElevator({ ...elevator }) */
              }}
            />
          </Grid>

          {selectedAccountingProgramList?.value === "PARAŞÜT" ? (
            <>
              <Grid item xs={6}>
                <TextField
                  label={t("arızakodu")}
                  variant="standard"
                  fullWidth
                  type="text"
                  value={billForm?.issueCode}
                  onChange={(e) => {
                    billForm.issueCode = e.target.value;
                    setBillForm({ ...billForm });
                  }}
                />
              </Grid>

              <Grid item xs={6}>
                <TextField
                  label={t("bakımkodu")}
                  variant="standard"
                  fullWidth
                  type="text"
                  value={billForm?.maintenanceCode}
                  onChange={(e) => {
                    billForm.maintenanceCode = e.target.value;
                    setBillForm({ ...billForm });
                  }}
                />
              </Grid>

              <Grid item xs={12}>
                <TextField
                  label={t("kullanıcıadı")}
                  variant="standard"
                  fullWidth
                  type="text"
                  value={billForm?.username}
                  onChange={(e) => {
                    billForm.username = e.target.value;
                    setBillForm({ ...billForm });
                  }}
                />
              </Grid>

              <Grid item xs={12}>
                <TextField
                  label={t("şifre")}
                  variant="standard"
                  fullWidth
                  type="text"
                  value={billForm?.password}
                  onChange={(e) => {
                    billForm.password = e.target.value;
                    setBillForm({ ...billForm });
                  }}
                />
              </Grid>

              <Grid item xs={12}>
                <TextField
                  label={t("clientid")}
                  variant="standard"
                  fullWidth
                  type="text"
                  value={billForm?.client_id}
                  onChange={(e) => {
                    billForm.client_id = e.target.value;
                    setBillForm({ ...billForm });
                  }}
                />
              </Grid>

              <Grid item xs={12}>
                <TextField
                  label={t("clientsecret")}
                  variant="standard"
                  fullWidth
                  type="text"
                  value={billForm?.client_secret}
                  onChange={(e) => {
                    billForm.client_secret = e.target.value;
                    setBillForm({ ...billForm });
                  }}
                />
              </Grid>
            </>
          ) : (
            <></>
          )}
        </Grid>
      </CustomDialog>
    </Box>
  );
};

export default Page;
