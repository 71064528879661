import axios from "axios";

export const getCompanyRequest = () => axios.get(`/settings`);

export const getBillInfo = () => axios.get(`/settings/bill_authorize`);

export const updateAccountingStatusRequest = (status) => axios.put(`/settings/accountingStatus/${status}`);

export const updateMasterAccountingStatusRequest = (status) => axios.put(`/settings/accountingStatus/master?status=${status}`);

export const updateEditPermissionStatusRequest = (status) => axios.put(`/settings/editPermissionStatus/${status}`);

export const updateMasterEditPermissionStatusRequest = (status) => axios.put(`/settings/editPermissionStatus/master?status=${status}`);

export const updateMasterIssueNotificationStatusRequest = (status) => axios.put(`/settings/issueNotificationStatus/master?status=${status}`);

export const updateMasterMaintenanceNotificationStatusRequest = (status) => axios.put(`/settings/maintenanceNotificationStatus/master?status=${status}`);

export const updateSmsSendingCreatedMaintenance = (status) => axios.put(`/settings/smsSending/createdMaintenance/?status=${status}`);

export const updateSmsSendingStartedMaintenance = (status) => axios.put(`/settings/smsSending/startedMaintenance/?status=${status}`);

export const updateSmsSendingFinishedMaintenance = (status) => axios.put(`/settings/smsSending/finishedMaintenance/?status=${status}`);

export const updateSmsSendingFinishedMaintenanceNote = (status) => axios.put(`/settings/smsSending/finishedMaintenance/note/?status=${status}`);

export const updateSmsSendingStartedIssue = (status) => axios.put(`/settings/smsSending/startedIssue/?status=${status}`);

export const updateSmsSendingFinishedIssue = (status) => axios.put(`/settings/smsSending/finishedIssue/?status=${status}`);

export const updateSmsSendingFinishedIssueNote = (status) => axios.put(`/settings/smsSending/finishedIssue/note/?status=${status}`);

export const updateMonthlyMaintenanceBalance = (status) => axios.put(`/settings/monthlyMaintenanceBalance/?status=${status}`);

export const updateSmsSendingFinishedMaintenanceBalance = (status) => axios.put(`/settings/smsSending/finishedMaintenance/balance/?status=${status}`);

export const updateSmsSendingFinishedIssueItems = (status) => axios.put(`/settings/smsSending/finishedIssueItemsSms/?status=${status}`);

export const updateSmsSendingFinishedIssueBalance = (status) => axios.put(`/settings/smsSending/finishedIssueWithBalanceSms/?status=${status}`);

export const authorizeBillRequest = (billForm) => axios.put(`/settings/bill_authorize`, billForm)
