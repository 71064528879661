import { DatePicker, LocalizationProvider, TimePicker } from "@mui/lab"
import AdapterDateFns from "@mui/lab/AdapterDateFns"
import { Paper, Typography, useTheme, Box, Badge, Button, Autocomplete, TextField, MenuItem, Divider } from "@mui/material"
import { format, getHours, getTime } from "date-fns"
import { tr } from "date-fns/locale"
import { useSnackbar } from "notistack"
import React, { useEffect, useState } from 'react'
import { useTranslation } from "react-i18next"
import NumberFormat from "react-number-format"
import { chargeRequest, payRequest } from "../../api/controllers/accounting-controller"
import { getUnReadRequest } from "../../api/controllers/event-history-controller"
import { getCurrentCompanyRequest, getCurrentUserRequest } from "../../api/controllers/home-controller"
import CustomDataGrid, { TableMenu } from "../../components/CustomDataGrid"
import CustomDialog from "../../components/CustomDialog"





const Page = () => {
    const { t } = useTranslation("common");
    document.title = 'Aselmind | ' + t("hesabım")
    const theme = useTheme()
    const { enqueueSnackbar } = useSnackbar();

    const [userData, setUserData] = useState({})
    const [companyData, setCompanyData] = useState({})

    // GET USER DATA
    const fetchUserData = async () => {
        try {
            let res = await getCurrentUserRequest()
            if (res) {
                setUserData(res?.data)
            }
        } catch (error) {
            console.log('error', error)
        }
    }

    // GET COMPANY LOGO
    const fetcCompanyLogo = async () => {
        try {
            let res = await getCurrentCompanyRequest()
            if (res) {
                setCompanyData(res?.data)
            }
        } catch (error) {

        }
    }


    useEffect(() => {
        fetchUserData()
        fetcCompanyLogo()

    }, [])


    return (
        <Box p={3}>

            <Paper
                elevation={0}
                sx={{
                    display: "flex",
                    padding: theme.spacing(2),
                    alignItems: "center",
                    justifyContent: "space-between",
                    flexDirection: { xs: 'column', sm: 'column', md: 'row', lg: "row" }
                }}
            >
                <Typography sx={{ fontSize: window.innerWidth < 675 ? "1.75rem" : "2.125rem" }} variant="h4">{t('hesabım')}</Typography>

            </Paper>
            <Paper
                elevation={0}
                sx={{
                    padding: theme.spacing(2),
                    margin: theme.spacing(2, 0),
                }}
            >

                <Box sx={{ display: "flex", padding: { sx: "", sm: "", md: "24px 48px" } }}>

                    <Box sx={{ display: "flex", flexDirection: "column", width: "100%" }}>

                        <Box sx={{ display: "flex", flexDirection: "row", padding: { xs: "12px 0", md: "16px 0" } }}>
                            <Typography sx={{ minWidth: "140px", fontweight: "400", color: "rgba(77,77,77,0.54)" }}>{t("kullanıcıadı")}</Typography>
                            <Box sx={{ padding: { xs: "0 8px", sm: "", md: "0 16px", lg: "", xl: "0 32px" }, color: "rgba(77,77,77,0.54)" }}>:</Box>
                            <Typography>{userData?.userTitle}</Typography>
                        </Box>

                        <Divider sx={{ width: "100%" }} />

                        <Box sx={{ display: "flex", flexDirection: "row", padding: { xs: "12px 0", md: "16px 0" } }}>
                            <Typography sx={{ minWidth: "140px", fontweight: "400", color: "rgba(77,77,77,0.54)" }}>{t("ad")}</Typography>
                            <Box sx={{ padding: { xs: "0 8px", sm: "", md: "0 16px", lg: "", xl: "0 32px" }, color: "rgba(77,77,77,0.54)" }}>:</Box>
                            <Typography>{userData?.userName}</Typography>
                        </Box>

                        <Divider sx={{ width: "100%" }} />

                        <Box sx={{ display: "flex", flexDirection: "row", padding: { xs: "12px 0", md: "16px 0" } }}>
                            <Typography sx={{ minWidth: "140px", fontweight: "400", color: "rgba(77,77,77,0.54)" }}>{t("soyad")}</Typography>
                            <Box sx={{ padding: { xs: "0 8px", sm: "", md: "0 16px", lg: "", xl: "0 32px" }, color: "rgba(77,77,77,0.54)" }}>:</Box>
                            <Typography>{userData?.userSurName}</Typography>
                        </Box>

                        <Divider sx={{ width: "100%" }} />

                        <Box sx={{ display: "flex", flexDirection: "row", padding: { xs: "12px 0", md: "16px 0" } }}>
                            <Typography sx={{ minWidth: "140px", fontweight: "400", color: "rgba(77,77,77,0.54)" }}>{t("yetkitipi")}</Typography>
                            <Box sx={{ padding: { xs: "0 8px", sm: "", md: "0 16px", lg: "", xl: "0 32px" }, color: "rgba(77,77,77,0.54)" }}>:</Box>
                            <Typography>{userData?.userType === 'Master' ? t("yönetici") : userData?.userType === 'Employee' ? t("çalışan") : <></>}</Typography>
                        </Box>

                        <Divider sx={{ width: "100%" }} />

                        <Box sx={{ display: "flex", flexDirection: "row", padding: { xs: "12px 0", md: "16px 0" }, flexWrap: "wrap" }}>
                            <Typography sx={{ minWidth: "140px", fontweight: "400", color: "rgba(77,77,77,0.54)" }}>{t("telefonnumarası")}</Typography>
                            <Box sx={{ padding: { xs: "0 8px", sm: "", md: "0 16px", lg: "", xl: "0 32px" }, color: "rgba(77,77,77,0.54)" }}>:</Box>
                            <Typography>{userData?.phone?.split(/[~]/g)[0] + " " + userData?.phone?.split(/[~]/g)[1]}</Typography>
                        </Box>

                        <Divider sx={{ width: "100%" }} />

                        <Box sx={{ display: "flex", flexDirection: "row", padding: { xs: "12px 0", md: "16px 0" }, flexWrap: "wrap" }}>
                            <Typography sx={{ minWidth: "140px", fontweight: "400", color: "rgba(77,77,77,0.54)" }}>{t("şirketlogosu")}</Typography>
                            <Box sx={{ padding: { xs: "0 8px", sm: "", md: "0 16px", lg: "", xl: "0 32px" }, color: "rgba(77,77,77,0.54)" }}>:</Box>
                            {companyData.logo ? <img src={companyData.logo} style={{ height: "auto", maxWidth: "48%", maxHeight: '50px', }} /> : <></>}
                        </Box>

                    </Box>
                </Box>

            </Paper >

        </Box >
    )

}

export default Page