import axios from "axios";

export const getAllRequest = () => axios.get(`/eventhistory`)

export const deleteEventHistoryRequest = ({ ref }) => axios.delete(`/eventhistory/${ref}`, {})

export const deleteEventHistoryListRequest = ({ idList }) => axios.delete(`/eventhistory/clear`, idList)

export const getEventHistoryByDateRequest = ({ localDate }) => axios.get(`/eventhistory/date?localDate=${localDate}`)

export const markReadRequest = () => axios.get(`/eventhistory/markread`)

export const markReadListRequest = ({ idList }) => axios.post(`/eventhistory/markread/list`, idList)

export const markSeenListRequest = ({ idList }) => axios.post(`/eventhistory/markseen/list`, idList)

export const markSeenRequest = () => axios.get(`/eventhistory/markseen`)

export const getNewRequest = () => axios.get(`/eventhistory/new`)

export const getNewEventHistoryCountRequest = () => axios.get(`/eventhistory/new/count`)

export const getReadRequest = () => axios.get(`/eventhistory/read`)

export const deleteReadEventHistoryRequest = () => axios.delete(`/eventhistory/read/clear`, {})

export const getSeenRequest = () => axios.get(`/eventhistory/seen`)

export const getSeenEventHistoryCountRequest = () => axios.get(`/eventhistory/seen/count`)

export const getUnReadRequest = () => axios.get(`/eventhistory/unread`)
