import { createTheme, ThemeProvider } from '@mui/material/styles';
import { trTR } from '@mui/x-data-grid';
import { trTR as coreTrTR } from '@mui/material/locale';
import { createFilterOptions } from '@mui/base';

const CustomThemeProvider = ({ children }) => {
    const theme = createTheme({
        palette: {
            type: 'light',
            primary: {
                main: '#4D4D4D',
                contrastText: '#FFFFFF',
            },
            secondary: {
                main: '#D05515',
                contrastText: '#FFFFFF',
            },
            text: {
                primary: '#4D4D4D',
            },
            background: {
                default: '#F5F5F5',
            },
            success: {
                main: 'rgba(39, 174, 96, .2)',
                contrastText: 'rgba(39, 174, 96, 1)',
            },
            error: {
                main: 'rgba(192, 57, 43, .2)',
                contrastText: 'rgba(192, 57, 43, 1)',
            },
            warning: {
                main: 'rgba(211, 84, 0, .2))',
                contrastText: 'rgba(211, 84, 0, 1)',
            }
        },
        components: {
            MuiTextField: {
                defaultProps: {
                    inputProps: {
                        style: {
                            fontSize: "14px"
                        }
                    },
                    InputLabelProps: {
                        style: {
                            fontSize: "14px"
                        }
                    }
                }
            },
            MuiButton: {
                defaultProps: {
                    disableElevation: true,
                    style: {
                        textTransform: "capitalize",
                        fontSize: "14px"
                    }
                }
            },
        },
        typography: {
            fontFamily: `'Poppins', sans-serif`,
            button: {
                textTransform: "capitalize"
            }
        },
    },
        coreTrTR,
        trTR,
    )

    return (
        <ThemeProvider theme={theme}>
            {children}
        </ThemeProvider>
    )

}

export default CustomThemeProvider