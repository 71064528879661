import { Button, MenuItem, Paper, Typography, useTheme, Box, TextField, Grid, Popover, Avatar, Fade, Menu, Autocomplete } from "@mui/material"
import { useSnackbar } from "notistack"
import React, { useEffect, useState } from 'react'
import NumberFormat from "react-number-format"
import { deleteUserRequest, getUserRequest, getUsersRequest, insertUserRequest, updateUserRequest } from "../../api/controllers/user-controller"
import CustomDataGrid, { TableMenu } from "../../components/CustomDataGrid"
import CustomDialog from "../../components/CustomDialog"
import { SketchPicker } from 'react-color';
import { useTranslation } from "react-i18next"
import jsPDF from "jspdf"
import "jspdf-autotable";
import { CSVLink } from "react-csv";
import DownloadIcon from '@mui/icons-material/Download';
import { ExpandMore } from "@mui/icons-material"
import { useHistory } from "react-router"
import { format, getMonth, getYear } from "date-fns"
import CountryPicker, { countries } from "../../components/CountryPicker";
import { LoadingButton } from "@mui/lab"
import { myFont } from "../../components/myFontForPdf"

const Page = () => {
    const { t } = useTranslation("common");
    document.title = 'Aselmind | ' + t("kullanıcılar")
    const theme = useTheme()
    const { enqueueSnackbar } = useSnackbar();
    const history = useHistory()

    const date = new Date();

    const [selectedCountry, setSelectedCountry] = useState(countries[223])
    const [selectedCountry2, setSelectedCountry2] = useState(null)
    // DATE SETTIGNS
    const [dateData, setDateData] = useState({
        year: getYear(date),
        month: getMonth(date),
        day: "",
        endDate: format(date, 'yyyy-MM-dd'),
        startDate: ""
    })
    const handleDate = (dateData) => {
        dateData.day = new Date(dateData.year, dateData.month, 0).getDate();
        setDateData({ ...dateData })

        if (getMonth(date) < 10) {
            dateData.startDate = dateData.year + "-" + "0" + dateData.month + "-" + dateData.day
            setDateData({ ...dateData })
        }
        else {
            dateData.startDate = dateData.year + "-" + dateData.month + "-" + dateData.day
            setDateData({ ...dateData })
        }

    }


    //MUI POPOVER
    const [anchorEl, setAnchorEl] = useState(null);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;
    const defaultUsersData = {
        colorHex: "#000000",
        lastPosition: { latitude: 0, longitute: 0 },
        phone: "",
        ref: "",
        userName: "",
        userSurName: "",
        userTitle: "",
        userType: "",
        userPassword: "",
        userPasswordAgain: "",
    }

    const [assignedUsers, setAssignedUsers] = useState([])

    //GET USERS

    const [data, setData] = useState([])

    const fetchUsers = async () => {
        try {
            let res = await getUsersRequest()
            if (res) {
                setData(res.data)
            }
        } catch (error) {
            enqueueSnackbar(t('birşeylertersgitti'), { variant: "error" });
            console.log(error)
        }
    }

    //CREATE USERS
    const [newUsersData, setNewUsersData] = useState(defaultUsersData)
    const [createUsersDialog, setCreateUsersDialog] = useState(false)

    const closeCreateUsersDialog = () => {
        setCreateUsersDialog(false)
        setNewUsersData(defaultUsersData)
        setSelectedCountry(countries[223])
    }

    const [isLoading, setIsLoading] = useState(false)

    const handleCreateUsers = async () => {
        if (newUsersData.userPassword === newUsersData.userPasswordAgain) {
            newUsersData.phone = selectedCountry?.phone + '~' + newUsersData?.phone
            setNewUsersData({ ...newUsersData })
            try {
                setIsLoading(true)
                let res = await insertUserRequest(newUsersData)
                if (res) {
                    closeCreateUsersDialog();
                    fetchUsers();
                    enqueueSnackbar(t('kullanıcıbaşarıylaeklendi'), { variant: "success" });
                    setIsLoading(false)
                }
            } catch (error) {
                enqueueSnackbar(t('birşeylertersgitti'), { variant: "error" });
                setIsLoading(false)
                console.log(error)
                newUsersData.phone = newUsersData.phone.split(/[~]/g)[1]
                setNewUsersData({ ...newUsersData })
            }
        } else {
            enqueueSnackbar(t('şifreleruyuşmuyor'), { variant: "error" });
        }

    }


    //UPDATE USERS
    const [selectedUsersData, setSelectedUsersData] = useState(defaultUsersData)
    const [updateUsersDialog, setUpdateUsersDialog] = useState(false)

    const openUpdateUsersDialog = async (Users) => {

        try {
            let res = await getUserRequest({ ref: Users.ref })
            if (res) {
                await setSelectedUsersData({ ...res.data, phone: res.data.phone.split(/[~]/g)[1] })
                await setUpdateUsersDialog(true)
                await setSelectedCountry2(countries.find((v) => v.phone === res.data.phone.split(/[~]/g)[0]))
            }

        } catch (error) {

        }
    }

    const closeUpdateUsersDialog = () => {
        setUpdateUsersDialog(false)
        setSelectedUsersData(defaultUsersData)
        setSelectedCountry(countries[223])
    }

    const handleUpdateUsers = async () => {
        if (selectedUsersData.userPassword === selectedUsersData.userPasswordAgain) {
            try {
                setIsLoading(true)
                let res = await updateUserRequest({ ...selectedUsersData, phone: selectedCountry2?.phone + '~' + selectedUsersData?.phone })
                if (res) {
                    closeUpdateUsersDialog()
                    enqueueSnackbar(t('kullanıcıbaşarıylagüncellendi'), { variant: "success" });
                    fetchUsers()
                    setIsLoading(false)
                }
            } catch (error) {
                console.log(error)
                setIsLoading(false)
                enqueueSnackbar(t('birşeylertersgitti'), { variant: "error" });
            }
        } else {
            enqueueSnackbar(t('şifreleruyuşmuyor'), { variant: "error" });

        }

    }


    //DELETE USERS  
    const [deleteUsersDialog, setDeleteUsersDialog] = useState(false)

    const openDeleteUsersDialog = (users) => {
        setSelectedUsersData(users)
        setDeleteUsersDialog(true)
    }

    const closeDeleteUsersDialog = () => {
        setDeleteUsersDialog(false)
        setSelectedUsersData(defaultUsersData)
    }

    const handleDeleteUsers = async () => {
        try {
            let res = await deleteUserRequest(selectedUsersData?.ref)
            if (res) {
                closeDeleteUsersDialog()
                fetchUsers()
                enqueueSnackbar(t('araçbaşarıylasilindi'), { variant: "success" });
            }
        } catch (error) {
            console.log(error)
            enqueueSnackbar(t('birşeylertersgitti'), { variant: "error" });
        }
    }

    const options = [
        { tr: 'Master', id: 1, label: t('yönetici') },
        { tr: 'Employee', id: 2, label: t('çalışan') },
    ];

    const columns = [
        {
            field: 'userTitle',
            headerName: t('kullanıcıadı'),
            minWidth: 130,
            flex: 1,
            valueGetter: ({ value }) => value ? value : <Typography sx={{ opacity: .6, fontSize: '14px' }}>{t("belirtilmemiş")}</Typography>,
        },
        {
            field: 'userName',
            headerName: t('ad'),
            minWidth: 130,
            flex: 1,
            valueGetter: ({ value }) => value ? value : <Typography sx={{ opacity: .6, fontSize: '14px' }}>{t("belirtilmemiş")}</Typography>,
        },
        {
            field: 'userSurName',
            headerName: t('soyad'),
            minWidth: 130,
            flex: 1,
            renderCell: ({ value }) => value ? value : <Typography sx={{ opacity: .6, fontSize: '14px' }}>{t("belirtilmemiş")}</Typography>,
        },
        {
            field: 'userType',
            headerName: t('yetkitipi'),
            minWidth: 130,
            flex: 1,
            renderCell: ({ value }) => value ? value : <Typography sx={{ opacity: .6, fontSize: '14px' }}>{t("belirtilmemiş")}</Typography>,
        },
        {
            field: 'phone',
            headerName: t('telefonnumarası'),
            minWidth: 130,
            flex: 1,
            renderCell: ({ value }) => value ? value?.split(/[~]/g)[1]?.length > 0 ? value?.split(/[~]/g)[0] + " " + value?.split(/[~]/g)[1] : <Typography sx={{ opacity: .6, fontSize: '14px' }}>{t("belirtilmemiş")}</Typography> : <Typography sx={{ opacity: .6, fontSize: '14px' }}>{t("belirtilmemiş")}</Typography>,
        },
        {
            field: 'ref',
            headerName: <></>,
            minWidth: 50,
            type: 'actions',
            align: 'right',
            flex: 1,
            renderCell: (params) => <TableMenu
                menuItems={
                    [
                        <MenuItem key={"issue"} onClick={() => history.push(`/islemler/ariza-emirleri?ref=${params?.row?.ref}&start=${dateData.startDate}&end=${dateData.endDate}&previousUrl=kullanici`)}>{t('atanmışarızalar')}</MenuItem>,
                        <MenuItem key={"maintance"} onClick={() => history.push(`/islemler/bakim-emirleri?ref=${params?.row?.ref}&start=${dateData.startDate}&end=${dateData.endDate}&previousUrl=kullanici`)}>{t('atanmışbakımlar')}</MenuItem>,
                        <MenuItem key={"debit"} onClick={() => history.push(`/kullanici/kisi-zimmet-bilgisi?ref=${params?.row?.ref}`)}>{t('zimmetbilgisi')}</MenuItem>,
                        <MenuItem key={"edit"} onClick={() => openUpdateUsersDialog(params?.row)}>{t('düzenle')}</MenuItem>,
                        <MenuItem key={"delete"} onClick={() => openDeleteUsersDialog(params?.row)}>{t('sil')}</MenuItem>
                    ]
                }
            />
        },
    ]

    // PDF EXPORTING

    const exportPdf = () => {
        const unit = "pt";
        const size = "A4"; // Use A1, A2, A3 or A4
        const orientation = "portrait"; // portrait or landscape

        const marginLeft = 40;
        const doc = new jsPDF(orientation, unit, size);

        doc.addFileToVFS("Amiri-Regular.ttf", myFont());
        doc.addFont("Amiri-Regular.ttf", "Amiri", "normal");
        doc.setFont("Amiri"); // set font
        doc.setFontSize(15);

        const title = t("kayıtlıkullanıcılar");
        const headers = [[t("kullanıcıadı"), t("ad"), t("soyad"), t("yetkitipi"), t("telefonnumarası")]];
        const data2 = data.map(elt => [elt.userTitle, elt.userName, elt.userSurName, elt.userType, elt?.phone ? elt?.phone?.split(/[~]/g)[1]?.length > 0 ? elt?.phone?.split(/[~]/g)[0] + " " + elt?.phone?.split(/[~]/g)[1] : "" : ""])

        let content = {
            startY: 50,
            head: headers,
            body: data2,
            styles: {
                font: 'Amiri',
                fontStyle: 'normal',
            },
            columnStyles: {
                0: { cellWidth: 140 },
                1: { cellWidth: 100 },
                2: { cellWidth: 70 },
                3: { cellWidth: 105 },
                4: { cellWidth: 100 },
                // etc 515px / alan 
            }
        };

        doc.text(title, marginLeft, 40);
        doc.autoTable(content);
        doc.save("report.pdf")
    }

    // EXCEL EXPORTING
    const headers5 = [
        { label: t("kullanıcıadı"), key: "kullanıcıadı" },
        { label: t("ad"), key: "ad" },
        { label: t("soyad"), key: "soyad" },
        { label: t("yazıtipi"), key: "yazıtipi" },
        { label: t("telefonnumarası"), key: "telefonnumarası" }
    ];

    const data5 = data.map((elt) => (
        { kullanıcıadı: elt.userTitle ? elt.userTitle : "", ad: elt.userName ? elt.userName : "", soyad: elt.userSurName ? elt.userSurName : "", yazıtipi: elt.userType ? elt.userType : "", telefonnumarası: elt.phone ? elt?.phone?.split(/[~]/g)[1]?.length > 0 ? elt?.phone?.split(/[~]/g)[0] + " " + elt?.phone?.split(/[~]/g)[1] : "" : "" }
    ))

    const [menuOpen, setMenuOpen] = useState(false)
    const [anchorEl2, setAnchorEl2] = useState(null)


    useEffect(() => {
        fetchUsers()
        handleDate(dateData)
    }, [])

    return (
        <Box p={3}>

            <Paper
                elevation={0}
                sx={{
                    display: "flex",
                    padding: theme.spacing(2),
                    alignItems: "center",
                    justifyContent: "space-between",
                    flexDirection: { xs: 'column', sm: 'column', md: 'row', lg: "row" }
                }}
            >
                <Typography sx={{ textAlign: "center", fontSize: window.innerWidth < 675 ? "1.75rem" : "2.125rem" }} variant="h4">{t('kayıtlıkullanıcılar')}</Typography>

            </Paper>
            <Paper
                elevation={0}
                sx={{
                    padding: theme.spacing(2),
                    margin: theme.spacing(2, 0),
                }}
            >

                <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", paddingBottom: "16px" }}>
                    <div
                        style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            //flexDirection: { xs: 'column', sm: 'column', md: 'column', lg: "row" }
                        }}
                    >
                        <Button onClick={() => setCreateUsersDialog(true)} disableElevation sx={{
                            margin: theme.spacing(1),
                            width: { xs: "100%", sm: "100%", md: "auto", lg: "auto" }
                        }} variant="contained" color="secondary">{t('yenikullanıcıekle')}</Button>
                    </div>

                    <Button
                        onClick={(e) => {
                            setAnchorEl2(e.currentTarget)
                            setMenuOpen(true);
                        }}
                        variant="outlined"
                        color="primary"
                        endIcon={<ExpandMore />}
                        sx={{
                            minHeight: { sm: "40px" },
                            maxHeight: "85.5px"
                        }}
                    >
                        {t("raporla")}
                    </Button>

                    <Menu
                        anchorEl={anchorEl2}
                        open={menuOpen}
                        onClose={() => {
                            setMenuOpen(false)
                            setAnchorEl(null)
                        }}
                        TransitionComponent={Fade}
                        PaperProps={{
                            elevation: 3,
                            sx: {
                                width: "auto",
                                minWidth: anchorEl2?.offsetWidth || "auto",
                            }
                        }}
                    >
                        <MenuItem>
                            <Button startIcon={<DownloadIcon />} variant="outlined" onClick={() => exportPdf()} sx={{ color: "#c0392b", borderColor: "#c0392b", minWidth: "100px" }} >{t("pdf")}</Button>
                        </MenuItem>

                        <MenuItem>
                            <CSVLink data={data5} headers={headers5} separator={";"} filename={"kayitlikullanicilar.csv"}>
                                <Button startIcon={<DownloadIcon />} variant="outlined" sx={{ color: "#4CAF50", borderColor: "#4CAF50", minWidth: "100px" }} >{t("excel")}</Button>
                            </CSVLink>
                        </MenuItem>

                    </Menu>

                </div>

                <CustomDataGrid
                    columns={columns}
                    data={data}
                    rowId={params => params?.ref}
                />
            </Paper>


            {/* CREATE USERS DIALOG */}
            <CustomDialog
                maxWidth="md"
                title={t('yenikullanıcıekleyin')}
                open={createUsersDialog}
                close={closeCreateUsersDialog}
                buttons={[
                    <Button key="cancel" onClick={() => closeCreateUsersDialog()}>{t('iptalet')}</Button>,
                    <LoadingButton loading={isLoading} key="save" onClick={() => handleCreateUsers()} disableElevation variant='contained' color='secondary'>
                        {t('kaydet')}
                    </LoadingButton>
                ]}
            >
                <Grid container spacing={2}>

                    <Grid item xs={12} md={6}>
                        <TextField
                            variant="standard"
                            label={t('ad')}
                            fullWidth
                            value={newUsersData?.userName}
                            onChange={(e) => {
                                newUsersData.userName = e.target.value
                                setNewUsersData({ ...newUsersData })
                            }}
                            required
                        />
                    </Grid>

                    <Grid item xs={12} md={6}>
                        <TextField
                            variant="standard"
                            label={t('soyad')}
                            fullWidth
                            value={newUsersData?.userSurName}
                            onChange={(e) => {
                                let value = e.target.value
                                value = value.replace(/\s/g, '');
                                newUsersData.userSurName = value
                                setNewUsersData({ ...newUsersData })
                            }}
                            required
                        />
                    </Grid>

                    <Grid item xs={12} md={6}>
                        <TextField
                            variant="standard"
                            label={t('kullanıcıadı')}
                            fullWidth
                            value={newUsersData?.userTitle}
                            onChange={(e) => {
                                let value = e.target.value
                                value = value.replace(/\s/g, '');
                                newUsersData.userTitle = value
                                setNewUsersData({ ...newUsersData })
                            }}
                            required
                        />
                    </Grid>

                    <Grid item xs={12} md={6}>
                        <Box style={{ display: "flex", flexDirection: "row" }}>
                            <CountryPicker value={selectedCountry ? selectedCountry : countries[223]} onchange={(e, newValue) => {
                                setSelectedCountry(newValue)
                            }} />
                            <NumberFormat
                                customInput={TextField}
                                allowEmptyFormatting
                                /*  format="+90 ### ### ## ##" */
                                variant="standard"
                                label={t('telefonnumarası')}
                                fullWidth
                                value={newUsersData?.phone}
                                onChange={(e) => {
                                    newUsersData.phone = e.target.value
                                    setNewUsersData({ ...newUsersData })
                                }}
                            />
                        </Box>
                    </Grid>

                    <Grid item xs={12} md={6}>
                        <Autocomplete
                            options={options}
                            renderInput={(params) => <TextField {...params} label={t("yetkitipiniseçiniz")} variant='standard' fullWidth InputLabelProps={{ required: true }} />}
                            /*  getOptionLabel={(option) => option.tr} */
                            filterOptions={(options, { inputValue }) => options.filter(option => option.label.toLocaleUpperCase().includes(inputValue.toLocaleUpperCase()))}

                            value={newUsersData?.userType === 'Master' ? 'Yönetici' : newUsersData?.userType === 'Employee' ? 'Çalışan' : ''}
                            onChange={(event, newValue) => {
                                newUsersData.userType = newValue.label === 'Yönetici' ? 'Master' : newValue.label === 'Çalışan' ? 'Employee' : ""
                                setNewUsersData({ ...newUsersData })
                            }}
                        />
                    </Grid>

                    <Grid item xs={12} md={6}>
                        <Button startIcon={<Avatar sx={{ width: 24, height: 24, bgcolor: newUsersData?.colorHex }} children="" />} aria-describedby={id} sx={{ width: "100%", height: "100%" }} variant="outlined" onClick={handleClick}>
                            {t('kullanıcıiçinbirrenkseçin')}
                        </Button>
                        <Popover
                            id={id}
                            open={open}
                            anchorEl={anchorEl}
                            onClose={handleClose}
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'left',
                            }}
                        >
                            <SketchPicker
                                disableAlpha={true}
                                color={newUsersData?.colorHex}
                                onChangeComplete={color => {
                                    handleClose();
                                    newUsersData.colorHex = color.hex
                                    setNewUsersData({ ...newUsersData })
                                }}
                            />
                        </Popover>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <TextField
                            variant="standard"
                            label={t('şifre')}
                            fullWidth
                            value={newUsersData?.userPassword}
                            onChange={(e) => {
                                newUsersData.userPassword = e.target.value
                                setNewUsersData({ ...newUsersData })
                            }}
                            required
                        />
                    </Grid>

                    <Grid item xs={12} md={6}>
                        <TextField
                            variant="standard"
                            label={t('şifretekrar')}
                            fullWidth
                            value={newUsersData?.userPasswordAgain}
                            onChange={(e) => {
                                let value = e.target.value
                                value = value.replace(/\s/g, '');
                                newUsersData.userPasswordAgain = value
                                setNewUsersData({ ...newUsersData })
                            }}
                            required
                        />
                    </Grid>
                </Grid>
            </CustomDialog>

            {/* USERS UPDATE DIALOG */}
            <CustomDialog
                maxWidth="md"
                title={t('kullanıcıgüncelleyin')}
                open={updateUsersDialog}
                close={() => closeUpdateUsersDialog()}
                buttons={[
                    <Button key="cancel" onClick={() => closeUpdateUsersDialog()}>{t('iptalet')}</Button>,
                    <LoadingButton loading={isLoading} key="save" onClick={() => handleUpdateUsers()} disableElevation variant='contained' color='secondary'>
                        {t('kaydet')}
                    </LoadingButton>
                ]}
            >
                <Grid container spacing={2}>

                    <Grid item xs={12} md={6}>
                        <TextField
                            variant="standard"
                            label={t('ad')}
                            fullWidth
                            value={selectedUsersData?.userName}
                            onChange={(e) => {
                                selectedUsersData.userName = e.target.value
                                setSelectedUsersData({ ...selectedUsersData })
                            }}
                            required
                        />
                    </Grid>

                    <Grid item xs={12} md={6}>
                        <TextField
                            variant="standard"
                            label={t('soyad')}
                            fullWidth
                            value={selectedUsersData?.userSurName}
                            onChange={(e) => {
                                let value = e.target.value
                                value = value.replace(/\s/g, '');
                                selectedUsersData.userSurName = value
                                setSelectedUsersData({ ...selectedUsersData })
                            }}
                            required
                        />
                    </Grid>

                    <Grid item xs={12} md={6}>
                        <TextField
                            variant="standard"
                            label={t('kullanıcıadı')}
                            fullWidth
                            value={selectedUsersData?.userTitle}
                            onChange={(e) => {
                                let value = e.target.value
                                value = value.replace(/\s/g, '');
                                selectedUsersData.userTitle = value
                                setSelectedUsersData({ ...selectedUsersData })
                            }}
                            required
                        />
                    </Grid>

                    <Grid item xs={12} md={6}>
                        <Box style={{ display: "flex", flexDirection: "row" }}>
                            <CountryPicker value={selectedCountry2 ? selectedCountry2 : countries[223]} onchange={(e, newValue) => {
                                setSelectedCountry2(newValue)
                            }} />
                            <NumberFormat
                                customInput={TextField}
                                variant="standard"
                                /*  format=" ### ### ## ## */
                                label={t('telefonnumarası')}
                                fullWidth
                                value={selectedUsersData?.phone}
                                onChange={(e) => {
                                    selectedUsersData.phone = e.target.value
                                    setSelectedUsersData({ ...selectedUsersData })
                                }}
                            />
                        </Box>
                    </Grid>

                    <Grid item xs={12} md={6}>
                        <Autocomplete
                            options={options}
                            renderInput={(params) => <TextField {...params} label={t("yetkitipiniseçiniz")} variant='standard' fullWidth InputLabelProps={{ required: true }} />}
                            /*   getOptionLabel={(option) => option.tr} */
                            filterOptions={(options, { inputValue }) => options.filter(option => option.label.toLocaleUpperCase().includes(inputValue.toLocaleUpperCase()))}
                            /*  isOptionEqualToValue={(option, value) => console.log('option', option.userType === value)} */
                            value={selectedUsersData?.userType === 'Master' ? 'Yönetici' : selectedUsersData?.userType === 'Employee' ? 'Çalışan' : ''}
                            onChange={(event, newValue) => {
                                selectedUsersData.userType = newValue.label === 'Yönetici' ? 'Master' : newValue.label === 'Çalışan' ? 'Employee' : ""
                                setSelectedUsersData({ ...selectedUsersData })
                            }}
                        />
                    </Grid>

                    <Grid item xs={12} md={6}>
                        <Button startIcon={<Avatar sx={{ width: 24, height: 24, bgcolor: selectedUsersData?.colorHex }} children="" />} aria-describedby={id} sx={{ width: "100%", height: "100%" }} variant="outlined" onClick={handleClick}>
                            {t('kullanıcıiçinbirrenkseçin')}
                        </Button>
                        <Popover
                            id={id}
                            open={open}
                            anchorEl={anchorEl}
                            onClose={handleClose}
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'left',
                            }}
                        >
                            <SketchPicker
                                disableAlpha={true}
                                color={selectedUsersData?.colorHex}
                                onChangeComplete={color => {
                                    handleClose();
                                    selectedUsersData.colorHex = color.hex
                                    setSelectedUsersData({ ...selectedUsersData })
                                }}
                            />
                        </Popover>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <TextField
                            variant="standard"
                            label={t('şifre')}
                            fullWidth
                            value={selectedUsersData?.userPassword}
                            onChange={(e) => {
                                selectedUsersData.userPassword = e.target.value
                                setSelectedUsersData({ ...selectedUsersData })
                            }}
                            required
                        />
                    </Grid>

                    <Grid item xs={12} md={6}>
                        <TextField
                            variant="standard"
                            label={t('şifretekrar')}
                            fullWidth
                            value={selectedUsersData?.userPasswordAgain}
                            onChange={(e) => {
                                let value = e.target.value
                                value = value.replace(/\s/g, '');
                                selectedUsersData.userPasswordAgain = value
                                setSelectedUsersData({ ...selectedUsersData })
                            }}
                            required
                        />
                    </Grid>
                </Grid>
            </CustomDialog>

            {/* DELETE USERS DIALOG */}
            <CustomDialog
                maxWidth="xs"
                title={t('eminmisiniz')}
                open={deleteUsersDialog}
                close={() => setDeleteUsersDialog(false)}
                buttons={[
                    <Button key="cancel" onClick={() => setDeleteUsersDialog(false)}>{t('iptalet')}</Button>,
                    <Button key="save" onClick={() => handleDeleteUsers()} disableElevation variant='contained' color='secondary'>
                        {t('sil')}
                    </Button>
                ]}
            >
                <b>{selectedUsersData?.userName}</b> {t('isimlikullanıcıyısilmekistediğinizeeminmisiniz')}
            </CustomDialog>
        </Box >
    )

}

export default Page