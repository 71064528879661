import { DataGrid, GRID_DEFAULT_LOCALE_TEXT, GridFooter, GridToolbar } from '@mui/x-data-grid'
import { IconButton, Menu } from "@mui/material"
import { MoreVert } from "@mui/icons-material"
import { useState } from 'react'
import { withStyles } from '@mui/styles'

let trLocale = GRID_DEFAULT_LOCALE_TEXT
trLocale.noRowsLabel = "Sonuç Bulunamadı"
trLocale.noResultsOverlayLabel = "Sonuç Bulunamadı"
trLocale.filterPanelColumns = "Sütunlar"
trLocale.filterPanelOperators = "Operatörler"
trLocale.filterPanelInputLabel = "Ara"
trLocale.filterPanelInputPlaceholder = "Tabloda arama yapabilirsiniz"
trLocale.toolbarExportLabel = "Çıktı Al"
trLocale.toolbarExport = "Çıktı Al"
trLocale.toolbarExportCSV = "Csv Olarak Çıktı Al"
trLocale.footerRowSelected = (number) => `${number} Satır Seçili`



export const TableMenu = ({ menuItems }) => {

    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <div>
            <IconButton onClick={handleClick}>
                <MoreVert />
            </IconButton>
            <Menu
                elevation={2}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
            >
                {menuItems}
            </Menu>
        </div>
    )
}

const StyledDataGrid = withStyles({
    root: {
        "& .MuiDataGrid-renderingZone": {
            maxHeight: "none !important",
        },
        "& .MuiDataGrid-cell": {
            lineHeight: "unset !important",
            maxHeight: "none !important",
            whiteSpace: "normal",
        },
        "& .MuiDataGrid-row": {
            maxHeight: "none !important",
        }
    }
})(DataGrid);

const CustomDataGrid = ({ data, columns, CustomFooterComponent, rowId, selectedRows, small, checkboxSelection, }) => {

    return (
        <StyledDataGrid
            rows={data}
            columns={columns}
            getRowId={rowId && rowId}
            onSelectionModelChange={selectedRows}
            localeText={trLocale}
            /* components={{
                Footer: () =>
                    <Grid
                        container
                        sx={{
                            flexDirection: { xs: "column", sm: "column", md: "row", lg: "row" },
                            alignItems: "center",
                            justifyContent: "space-between"
                        }}
                    >
                        {CustomFooterComponent || <></>}
                        <GridFooter />

                    </Grid>,
            }} */

            checkboxSelection={checkboxSelection ? checkboxSelection : false}
            disableColumnMenu
            style={{
                minHeight: small ? "60vh" : "65vh",
            }}
        />
    )


}



export default CustomDataGrid;