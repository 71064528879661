import axios from "axios";

export const CityGetAllOperationRequest = () => axios.get(`/addresses/cities`)

export const GetTownsOfCityWithIdRequest = ({ id }) => axios.get(`/addresses/cities/${id}/towns`)

export const FindByCityTownNeigborhoodRequest = ({ city, town, neighborhood }) => axios.get(`/addresses/find?city=${city}&town=${town}&neighborhood=${neighborhood}`)

export const GeocodeOfPositionPostRequest = ({ displayName, district, housenumber, neighborhood, province, street }) => axios.post(`/addresses/geocode`, { displayName, district, housenumber, neighborhood, province, street })

export const GeocodeOfPositionGetRequest = ({ adres }) => axios.get(`/addresses/geocodeSTR?adres=${adres}`)

export const NeigborhoodGetAllOperationRequest = ({ filter }) => axios.get(`/addresses/neighborhoods?filter=${filter}`)

export const GeocodeOfAdress = ({ latitude, longitute }) => axios.post(`/addresses/reversegeocode`, { latitude, longitute })

export const TownGetByIdOperationRequest = ({ id }) => axios.get(`/addresses/town/${id}`)

export const GetNeighborhoodsOfTownsWithIdRequest = ({ id }) => axios.get(`/addresses/town/${id}/neighborhoods`)

export const TownGetAllOperationRequest = ({ filter }) => axios.get(`/addresses/towns?filter=${filter}`)
