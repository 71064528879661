import React from 'react'
import "./suspense.css"

const SuspenseFallback = () => {
    return (
        <div style={{
            width: "100vw",
            height: "100vh",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
        }}>
{/*             <object height="500px" width="500px" type="image/svg+xml" data="/assets/aselmind-logo.svg"></object>
 */}            <img src={'/assets/aselmind-logo.svg'} />

        </div>
    )
}

export default SuspenseFallback