import axios from "axios";

export const getAllItemsRequest = () => axios.get(`/item`)

export const createItemRequest = (item) => axios.post(`/item`, item)

export const updateItemRequest = (item) => axios.put(`/item`, item)

export const getItemRequest = (ref) => axios.get(`/item/${ref}`)

export const deleteItemRequest = (ref) => axios.delete(`/item/${ref}`)

export const uploadItemsExcelRequest = (formdata) => axios.post(`/item/excel/save`, formdata)