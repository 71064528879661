import axios from "axios";

export const getUsersRequest = () => axios.get(`/user`)

export const insertUserRequest = (newUsersData) => axios.post(`/user`, newUsersData)

export const updateUserRequest = (selectedUsersData) => axios.put(`/user`, selectedUsersData)

export const getUserRequest = ({ ref }) => axios.get(`/user/${ref}`)

export const deleteUserRequest = (ref) => axios.delete(`/user/${ref}`)

export const updateQrPermissions = (value) => axios.put(`/user/qr/permissions?permission=${value}`)

export const updatePermissionForElevatorDisplayRequest = (value) => axios.put(`/user/elevator/display/permissions?permission=${value}`)
