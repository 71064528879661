import React, { lazy, Suspense } from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';

import CustomLayout from './CustomLayout'

/**
 * suspense olursa menu kayıyor.
 */
// const kullanici_ayarlar = lazy(() => import('./pages/kullanici/ayarlar'));
// const kullanici_hesabim = lazy(() => import('./pages/kullanici/hesabim'));
// const kullanici_kullanicilar = lazy(() => import('./pages/kullanici/kullanicilar'));
import kullanici_ayarlar from '../pages/kullanici/ayarlar'
import kullanici_hesabim from '../pages/kullanici/hesabim'
import support from '../pages/support'
import kullanici_bildirimler from '../pages/kullanici/bildirimler'
import kullanici_kullanicilar from '../pages/kullanici/kullanicilar'
import kullanici_kisi_zimmet_bilgisi from '../pages/kullanici/kisi-zimmet-bilgisi'
import SuspenseFallback from './SuspenseFallback';
import PrivateRoute from './PrivateRoute';

const not_found = lazy(() => import('../pages/NotFound'));
const login = lazy(() => import('../pages/Login'));
const dashboard = lazy(() => import('../pages/dashboard'));

const tanimlar_binalar = lazy(() => import('../pages/tanimlar/binalar'));
const tanimlar_binaEkle = lazy(() => import('../pages/tanimlar/bina-ekle'));
const tanimlar_binaDuzenle = lazy(() => import('../pages/tanimlar/bina-duzenle'));
const tanimlar_gruplar = lazy(() => import('../pages/tanimlar/gruplar'));
const tanimlar_depolar = lazy(() => import('../pages/tanimlar/depolar'));
const tanimlar_tesisler = lazy(() => import('../pages/tanimlar/tesisler'));
const tanimlar_araclar = lazy(() => import('../pages/tanimlar/araclar'));
const tanimlar_malzeme_listesi = lazy(() => import('../pages/tanimlar/malzeme-listesi'));
const tanimlar_demirbas_listesi = lazy(() => import('../pages/tanimlar/demirbas-listesi'));

const islemler_harita = lazy(() => import('../pages/islemler/harita'));
const islemler_muhasebe = lazy(() => import('../pages/islemler/muhasebe'));
const islemler_muhasebe_detay = lazy(() => import('../pages/islemler/muhasebe_detay'));
const islemler_takip = lazy(() => import('../pages/islemler/takip'));

const islemler_ariza_emirleri = lazy(() => import('../pages/islemler/bitmemis_arizalar'));
const islemler_bakim_emirleri = lazy(() => import('../pages/islemler/bitmemis_bakimlar'));

const raporlar_bitmis_arizalar = lazy(() => import('../pages/raporlar/bitmis_arizalar'));
const raporlar_bitmis_arizalar_detay = lazy(() => import('../pages/raporlar/bitmis_arizalar_detay'));
const raporlar_bitmis_bakimlar = lazy(() => import('../pages/raporlar/bitmis_bakimlar'));
const raporlar_bitmis_bakimlar_detay = lazy(() => import('../pages/raporlar/bitmis_bakimlar_detay'));
const raporlar_aylik_bakim_raporu = lazy(() => import('../pages/raporlar/aylik_bakim_raporu'));
const raporlar_aylik_malzeme_raporu = lazy(() => import('../pages/raporlar/aylik_malzeme_raporu'));



const Router = () => {

    return (
        <BrowserRouter>
            <Suspense fallback={<SuspenseFallback />}>
                <Switch>

                    <Route exact path="/login" component={login} />
                    <Route exact path="/register" component={login} />
                    <Route exact path="/forgot-password" component={login} />
                    <Route exact path="/reset-password" component={login} />

                    <PrivateRoute exact path="/" component={dashboard} />
                    <PrivateRoute exact path="/kullanici/ayarlar" component={kullanici_ayarlar} />
                    <PrivateRoute exact path="/kullanici/hesabim" component={kullanici_hesabim} />
                    <PrivateRoute exact path="/support" component={support} />
                    <PrivateRoute exact path="/kullanici/kullanicilar" component={kullanici_kullanicilar} />
                    <PrivateRoute exact path="/kullanici/bildirimler" component={kullanici_bildirimler} />
                    <PrivateRoute exact path="/tanimlar/binalar" component={tanimlar_binalar} />
                    <PrivateRoute exact path="/tanimlar/bina-ekle" component={tanimlar_binaEkle} />
                    <PrivateRoute exact path="/tanimlar/bina-duzenle" component={tanimlar_binaDuzenle} />
                    <PrivateRoute exact path="/tanimlar/gruplar" component={tanimlar_gruplar} />
                    <PrivateRoute exact path="/tanimlar/depolar" component={tanimlar_depolar} />
                    <PrivateRoute exact path="/kullanici/kisi-zimmet-bilgisi" component={kullanici_kisi_zimmet_bilgisi} />
                    <PrivateRoute exact path="/tanimlar/tesisler" component={tanimlar_tesisler} />
                    <PrivateRoute exact path="/tanimlar/araclar" component={tanimlar_araclar} />
                    <PrivateRoute exact path="/tanimlar/malzeme-listesi" component={tanimlar_malzeme_listesi} />
                    <PrivateRoute exact path="/tanimlar/demirbas-listesi" component={tanimlar_demirbas_listesi} />
                    <PrivateRoute exact path="/islemler/harita" component={islemler_harita} />
                    <PrivateRoute exact path="/islemler/muhasebe" component={islemler_muhasebe} />
                    <PrivateRoute exact path="/islemler/muhasebe_detay" component={islemler_muhasebe_detay} />
                    <PrivateRoute exact path="/islemler/takip" component={islemler_takip} />
                    <PrivateRoute exact path="/islemler/ariza-emirleri" component={islemler_ariza_emirleri} />
                    <PrivateRoute exact path="/islemler/bakim-emirleri" component={islemler_bakim_emirleri} />
                    <PrivateRoute exact path="/raporlar/bitmis-arizalar" component={raporlar_bitmis_arizalar} />
                    <PrivateRoute exact path="/raporlar/bitmis-arizalar-detay" component={raporlar_bitmis_arizalar_detay} />
                    <PrivateRoute exact path="/raporlar/bitmis-bakimlar" component={raporlar_bitmis_bakimlar} />
                    <PrivateRoute exact path="/raporlar/bitmis-bakimlar-detay" component={raporlar_bitmis_bakimlar_detay} />
                    <PrivateRoute exact path="/raporlar/aylik-bakim-raporu" component={raporlar_aylik_bakim_raporu} />
                    <PrivateRoute exact path="/raporlar/aylik-malzeme-raporu" component={raporlar_aylik_malzeme_raporu} />



                    <Route component={not_found} />

                </Switch>
            </Suspense>
        </BrowserRouter>
    )

}

export default Router

