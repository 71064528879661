import { useState, useEffect } from "react"
import { Redirect, Route } from "react-router-dom"
import { getCurrentUserRequest } from "../api/controllers/home-controller"
import CustomLayout from "./CustomLayout"
import SuspenseFallback from "./SuspenseFallback"



const PrivateRoute = ({ component: Component, ...rest }) => {


    const [AUTH_STATUS, setAUTH_STATUS] = useState("WAITING")



    const checkAuthentication = async () => {
        try {
            let res = await getCurrentUserRequest()
            if (res?.data) {
                setTimeout(() => {
                    setAUTH_STATUS("SUCCESS")
                }, 1000);
            }
        } catch (error) {
            if (error?.response?.status === 401) {
                setAUTH_STATUS("FAILED")
            }
        }

    }

    useEffect(() => {

        (
            async () => {
                await checkAuthentication()
            }
        )()

        return () => {

        }
    }, [])



    return (
        <Route  {...rest} render={props =>
            AUTH_STATUS === "WAITING" ? <SuspenseFallback />
                : AUTH_STATUS === "FAILED" ? <Redirect to={{ pathname: "/login" }} />
                    : AUTH_STATUS === "SUCCESS" ? <CustomLayout> <Component {...props} /></CustomLayout>
                        : <div>Error</div>
        } />
    )
}

export default PrivateRoute